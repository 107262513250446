@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext);
@font-face {
  font-family: "Akrobat Light";
  src: url(/static/media/Akrobat-Light.75a8c0f3.eot) format("eot");
  src: url(/static/media/Akrobat-Light.ee70a587.woff2) format("woff2"), url(/static/media/Akrobat-Light.74684ef6.woff) format("woff"), url(/static/media/Akrobat-Light.69c9774a.ttf) format("truetype"); }

@font-face {
  font-family: "Akrobat Regular";
  src: url(/static/media/Akrobat-Regular.2c142dcf.eot) format("eot");
  src: url(/static/media/Akrobat-Regular.648b2c6f.woff2) format("woff2"), url(/static/media/Akrobat-Regular.5b37ea1d.woff) format("woff"), url(/static/media/Akrobat-Regular.da3cb340.ttf) format("truetype"); }

@font-face {
  font-family: "Akrobat SemiBold";
  src: url(/static/media/Akrobat-SemiBold.00c747fe.eot) format("eot");
  src: url(/static/media/Akrobat-SemiBold.2142f350.woff2) format("woff2"), url(/static/media/Akrobat-SemiBold.b3190b7f.woff) format("woff"), url(/static/media/Akrobat-SemiBold.90597676.ttf) format("truetype"); }

@font-face {
  font-family: "Akrobat Bold";
  src: url(/static/media/Akrobat-Bold.8026a37f.eot) format("eot");
  src: url(/static/media/Akrobat-Bold.1089187a.woff2) format("woff2"), url(/static/media/Akrobat-Bold.598f6214.woff) format("woff"), url(/static/media/Akrobat-Bold.2f95fa5f.ttf) format("truetype"); }

@font-face {
  font-family: "Akrobat ExtraBold";
  src: url(/static/media/Akrobat-ExtraBold.7679e2b1.eot) format("eot");
  src: url(/static/media/Akrobat-ExtraBold.2f72c1d5.woff2) format("woff2"), url(/static/media/Akrobat-ExtraBold.9b8c9188.woff) format("woff"), url(/static/media/Akrobat-ExtraBold.2ff979cf.ttf) format("truetype"); }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block; }

b, strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th, td, caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

sub, sup, small {
  font-size: 75%; }

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after, .clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ''; }

* {
  box-sizing: border-box; }

body {
  background-color: #EDF2F8;
  color: #424B5A;
  font-family: "Akrobat Regular", sans-serif; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.container {
  width: 100%;
  padding: 0 150px; }

@media screen and (max-width: 1410px) {
  .container {
    padding: 0 100px; } }

button {
  font-family: "Akrobat Regular", sans-serif; }

.flex {
  display: flex; }

.justify-content-between {
  justify-content: space-between; }

.align-items-center {
  align-items: center; }

.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-center {
  display: flex;
  align-items: center; }

.flex-center-btw {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .flex-center-btw .add_btn {
    margin-right: 0; }

.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

hr {
  width: 100%;
  height: 1px;
  background-color: #C2D1D9;
  margin-bottom: 20px; }

.dashboard_block {
  background-color: #ffffff;
  border-radius: 5px;
  min-height: 197px;
  width: 24.2%;
  display: inline-block;
  padding: 15px 15px 10px;
  margin-bottom: 30px; }
  .dashboard_block hr {
    margin: 0; }

.date_wrapper {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  display: flex;
  align-items: center; }
  .date_wrapper img {
    margin-right: 5px; }

.block_label {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px; }

.block_value {
  color: #334150;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; }

.block_info_item {
  margin-bottom: 10px; }

.app-back_link {
  margin: 48px 0;
  display: inline-block; }

.page_options_wrapper {
  width: 50%; }

.title-block h1 {
  color: #334150;
  font-size: 60px;
  line-height: 75px;
  margin-bottom: 30px;
  font-weight: 400; }

div .add_btn {
  color: #0253B3;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  text-transform: none;
  margin-right: -5px;
  padding: 0; }
  div .add_btn img {
    margin-left: 9px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
  div .add_btn:hover {
    background: none;
    text-decoration: underline; }
    div .add_btn:hover img {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

div .blue_btn {
  color: #0253B3;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  padding: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  div .blue_btn:hover {
    text-decoration: underline;
    background: transparent; }

div .red_btn {
  color: #D0021B;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  padding: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  div .red_btn:hover {
    text-decoration: underline;
    background: transparent; }

.green_text {
  color: #2CC197; }

.red_text {
  color: #D0021B; }

textarea {
  font-family: Akrobat Regular, sans-serif; }

.dialog_description {
  font-family: Akrobat Regular, sans-serif;
  color: #334150;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px; }

.hot_offers_dialog {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  max-height: 380px;
  overflow: auto;
  padding-right: 10px; }
  .hot_offers_dialog::-webkit-scrollbar {
    height: 6px;
    top: 10px;
    width: 6px; }
  .hot_offers_dialog::-webkit-scrollbar-track {
    border-radius: 2px;
    background: #EDF2F8; }
  .hot_offers_dialog::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #0253B3; }
  .hot_offers_dialog::-webkit-scrollbar-thumb:window-inactive {
    background: #0253B3; }
  .hot_offers_dialog .product_item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #C2D1D9; }
    .hot_offers_dialog .product_item:last-of-type {
      border-bottom: none; }
    .hot_offers_dialog .product_item > div {
      width: 100%; }
    .hot_offers_dialog .product_item .title {
      font-size: 16px;
      color: #7F8FA4; }
      .hot_offers_dialog .product_item .title span {
        color: #0253B3; }
  .hot_offers_dialog .product_info {
    display: flex;
    align-items: center;
    width: 100%; }
    .hot_offers_dialog .product_info > div {
      width: 100%; }
    .hot_offers_dialog .product_info .delivery_time div {
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
      line-height: 15px; }
    .hot_offers_dialog .product_info .delivery_time p {
      color: #334150;
      font-size: 14px;
      line-height: 17px; }
    .hot_offers_dialog .product_info .price {
      font-weight: 600;
      font-size: 23px;
      line-height: 27px; }
      .hot_offers_dialog .product_info .price span {
        font-family: 'Roboto', sans-serif;
        font-weight: 400; }
    .hot_offers_dialog .product_info .cart_icon {
      margin-left: 36px;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      cursor: pointer; }
      .hot_offers_dialog .product_info .cart_icon:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }

.gap-5 {
  grid-gap: 5px;
  gap: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.gap-10 {
  grid-gap: 10px;
  gap: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.gap-15 {
  grid-gap: 15px;
  gap: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.gap-20 {
  grid-gap: 20px;
  gap: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.gap-25 {
  grid-gap: 25px;
  gap: 25px; }

.mt-25 {
  margin-top: 25px; }

.mb-25 {
  margin-bottom: 25px; }

.gap-30 {
  grid-gap: 30px;
  gap: 30px; }

.mt-30 {
  margin-top: 30px; }

.mb-30 {
  margin-bottom: 30px; }

.gap-35 {
  grid-gap: 35px;
  gap: 35px; }

.mt-35 {
  margin-top: 35px; }

.mb-35 {
  margin-bottom: 35px; }

.gap-40 {
  grid-gap: 40px;
  gap: 40px; }

.mt-40 {
  margin-top: 40px; }

.mb-40 {
  margin-bottom: 40px; }

.gap-45 {
  grid-gap: 45px;
  gap: 45px; }

.mt-45 {
  margin-top: 45px; }

.mb-45 {
  margin-bottom: 45px; }

.gap-50 {
  grid-gap: 50px;
  gap: 50px; }

.mt-50 {
  margin-top: 50px; }

.mb-50 {
  margin-bottom: 50px; }

.gap-55 {
  grid-gap: 55px;
  gap: 55px; }

.mt-55 {
  margin-top: 55px; }

.mb-55 {
  margin-bottom: 55px; }

.gap-60 {
  grid-gap: 60px;
  gap: 60px; }

.mt-60 {
  margin-top: 60px; }

.mb-60 {
  margin-bottom: 60px; }

.gap-65 {
  grid-gap: 65px;
  gap: 65px; }

.mt-65 {
  margin-top: 65px; }

.mb-65 {
  margin-bottom: 65px; }

div .custom_badge {
  background-color: #249AF3; }
  div .custom_badge.notification_badge {
    top: 15px;
    right: 15px;
    border: 1px solid #01397C;
    height: 8px;
    min-width: 8px;
    z-index: 0; }

.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .loader_wrapper > div {
    color: #0253B3; }

.popper_wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #334150;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  line-height: 17px;
  padding: 5px 20px;
  width: 420px;
  position: relative;
  margin-top: 5px; }
  .popper_wrapper::-webkit-scrollbar {
    height: 6px;
    top: 10px;
    width: 6px; }
  .popper_wrapper::-webkit-scrollbar-track {
    border-radius: 2px;
    background: #EDF2F8; }
  .popper_wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #0253B3; }
  .popper_wrapper::-webkit-scrollbar-thumb:window-inactive {
    background: #0253B3; }

.popper_loader {
  height: 72px !important; }

.default_button_wrapper .default_button {
  text-align: center;
  display: inline-flex;
  font-family: "Akrobat SemiBold", sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  min-width: 147px;
  padding: 0 10px;
  border-radius: 3px;
  box-shadow: none;
  white-space: nowrap;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .default_button_wrapper .default_button:disabled {
    opacity: 0.7; }
  .default_button_wrapper .default_button.default_button_contained {
    background-color: #0253B3;
    color: #ffffff;
    border: 1px solid #0253B3; }
  .default_button_wrapper .default_button.default_button_outlined {
    color: #ffffff;
    border: 1px solid #ffffff; }
    .default_button_wrapper .default_button.default_button_outlined.cancel_btn {
      border: 1px solid #7F8FA4;
      color: #7F8FA4; }
    .default_button_wrapper .default_button.default_button_outlined.blue_btn {
      border: 1px solid #0253B3;
      color: #0253B3; }
    .default_button_wrapper .default_button.default_button_outlined.delete_btn {
      border: 1px solid #D0021B;
      color: #D0021B; }
  .default_button_wrapper .default_button div {
    color: #ffffff; }

.default_button_wrapper .full_btn_width {
  width: 100%; }

.buy-error span {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px; }

.buy-error h1 {
  color: #334150;
  font-family: Akrobat;
  font-size: 32px;
  line-height: 40px;
  font-weight: 300;
  padding-bottom: 25px;
  border-bottom: 1px solid #C2D1D9; }

.buy-error .default_button_wrapper {
  margin-top: 25px; }
  .buy-error .default_button_wrapper .buy-error-ok {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; }
  .buy-error .default_button_wrapper button {
    /*margin-top: 20px;*/ }
    .buy-error .default_button_wrapper button span {
      position: relative;
      width: auto;
      height: 15px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }

.info-success-buy {
  display: flex;
  width: 420px;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 20px;
  position: fixed;
  left: 50%;
  bottom: 10%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: #fff;
  z-index: 10;
  border-radius: 5px; }
  .info-success-buy > div {
    display: flex;
    flex-flow: row;
    align-items: center; }
    .info-success-buy > div > p {
      color: #334150;
      font-family: Akrobat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0; }
    .info-success-buy > div > span {
      margin-right: 5px; }
      .info-success-buy > div > span > img {
        margin-top: -2.5px; }
  .info-success-buy span.close {
    cursor: pointer; }

button:disabled {
  background: grey;
  opacity: 0.65;
  cursor: not-allowed; }

.app-head {
  background-color: #01397C; }
  .app-head a {
    text-decoration: none; }

.header-wrapper {
  height: 80px; }

.app-nav {
  margin-left: 80px; }
  .app-nav a {
    font-size: 16px; }

.nav_link {
  opacity: 0.5;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  padding: 10px 20px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .nav_link:hover {
    opacity: 1; }
  .nav_link.active_nav_link {
    opacity: 1; }

.app-head_options {
  display: flex; }
  .app-head_options > :first-child {
    margin-right: 10px; }
  .app-head_options .exit_btn {
    opacity: 0.5;
    color: #FFFFFF;
    font-family: "Akrobat Light", sans-serif;
    font-size: 14px;
    line-height: 13px;
    font-weight: 300;
    text-transform: none;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .app-head_options .exit_btn:hover {
      opacity: 1; }
  .app-head_options .notification_btn {
    padding: 6px; }
    .app-head_options .notification_btn svg {
      fill: #ffffff;
      font-size: 28px; }

.basket-btn {
  padding: 0 20px;
  display: flex;
  align-items: center; }
  .basket-btn a {
    position: relative;
    cursor: pointer; }
  .basket-btn .item-basket {
    position: absolute;
    top: -5px;
    right: -2px;
    font-size: 12px;
    background-color: #249AF3;
    border-radius: 50%;
    height: 16px;
    padding: 0 4px;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; }

.balance {
  text-align: right;
  display: flex;
  flex-flow: column;
  justify-content: center; }
  .balance div {
    line-height: 1;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500; }
  .balance span {
    color: #FFFFFF; }
  .balance .balance__payment-block {
    font-size: 16px;
    font-weight: 500; }
  .balance .payment {
    color: #FFFFFF;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .balance .payment:hover {
      color: #e8e8e8; }
  .balance .late-payment {
    color: #e3041e;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .balance .late-payment:hover {
      color: #D0021B; }

.notification_list hr {
  margin: 0; }

.notification_item {
  padding: 10px 0;
  cursor: pointer; }
  .notification_item .flex-center {
    margin-bottom: 5px; }
    .notification_item .flex-center > span, .notification_item .flex-center > img {
      margin-right: 10px; }

.notification_star {
  position: relative;
  cursor: default;
  display: inline-block;
  color: #f5a623;
  font-size: 18px; }

.notification_title {
  color: #A7ADB3;
  font-family: "Akrobat Light", sans-serif;
  font-size: 14px;
  line-height: 17px; }
  .notification_title span {
    color: #334150;
    font-family: "Akrobat SemiBold", sans-serif;
    font-weight: 600; }

.notification_date {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px; }

.info-head {
  height: 200px;
  background-color: #01397C;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px; }
  .info-head .edit_btn {
    align-self: flex-end;
    background: transparent;
    box-shadow: none;
    color: #EFF2F4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    padding: 0;
    margin-right: -5px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .info-head .edit_btn img {
      margin-left: 9px; }
    .info-head .edit_btn:hover {
      text-decoration: underline;
      background: transparent; }

.info-header {
  display: flex;
  padding-bottom: 11px; }

.info-head-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #01397C;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%; }

.info-head_content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }

.info-head_title {
  font-size: 60px;
  font-weight: 600;
  line-height: 69px; }

.info-head_contacts {
  display: flex;
  margin-top: 20px; }
  .info-head_contacts p {
    display: inline-flex;
    align-items: center;
    margin-right: 70px; }
    .info-head_contacts p svg, .info-head_contacts p img {
      margin-right: 10px; }

.orders-main-block .dashboard-section_content_orders {
  padding-top: 30px;
  display: block; }
  .orders-main-block .dashboard-section_content_orders:after {
    content: "";
    display: table;
    clear: both; }

.orders-main-block .pagination_wrapper {
  padding-top: 0; }

.order-item {
  position: relative;
  float: left;
  margin-right: 2%;
  width: 23.5%;
  height: 290px;
  cursor: pointer;
  border-radius: 0; }
  .order-item:nth-child(4n) {
    margin-right: 0; }
  .order-item .block_info_notif {
    display: inline-block;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #249AF3;
    margin-top: 10px; }
  .order-item .block_info_item .block_value {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .order-item .block_info_item .block_value_currency {
    min-width: 90px;
    text-align: right;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .order-item .block_info_item .block_value_currency i {
      font-size: 15px; }
  .order-item .block_info_item .handler-hover-area {
    cursor: pointer;
    position: relative; }
    .order-item .block_info_item .handler-hover-area:hover .block_value_all {
      height: auto;
      opacity: 1; }
      .order-item .block_info_item .handler-hover-area:hover .block_value_all p {
        display: flex;
        justify-content: space-between; }
  .order-item .block_info_item .block_value_all_wrapper > span {
    display: inline-block; }
  .order-item .block_info_item .block_value_all {
    position: absolute;
    min-width: calc(100% + 30px);
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid rgba(127, 143, 164, 0.5);
    border-radius: 4px;
    padding: 15px;
    top: 20px;
    right: -15px;
    height: 0;
    opacity: 0;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: all ease .3s;
    transition: all ease .3s; }
    .order-item .block_info_item .block_value_all p {
      font-family: "Akrobat Regular", sans-serif;
      color: #334150;
      width: 100%;
      font-size: 16px;
      display: none;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #C2D1D9; }
      .order-item .block_info_item .block_value_all p:first-child {
        padding-top: 0; }
      .order-item .block_info_item .block_value_all p:last-child {
        border-bottom: none;
        padding-bottom: 0; }
      .order-item .block_info_item .block_value_all p span i {
        font-style: normal; }
      .order-item .block_info_item .block_value_all p span:first-child {
        max-width: 70%;
        overflow: hidden;
        word-break: break-all; }
        .order-item .block_info_item .block_value_all p span:first-child i {
          float: none;
          display: block; }
          .order-item .block_info_item .block_value_all p span:first-child i:last-child {
            color: #7F8FA4;
            font-size: 14px; }
      .order-item .block_info_item .block_value_all p span:last-child {
        min-width: 90px;
        text-align: right; }

div.select_wrapper {
  width: 275px;
  height: 40px;
  margin-left: 30px;
  background-color: #FFFFFF;
  -webkit-transition: all ease .3s;
  transition: all ease .3s; }
  div.select_wrapper .select__indicators, div.select_wrapper .select__menu * {
    cursor: pointer; }
  div.select_wrapper:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05); }
  div.select_wrapper div.select__value-container {
    height: 100%;
    cursor: text; }
  div.select_wrapper div.select_settings {
    height: 40px;
    color: #7F8FA4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    box-sizing: border-box;
    border: 1px solid #C2D1D9;
    border-radius: 3px; }
    div.select_wrapper div.select_settings .select_settings_select {
      background-color: #FFFFFF;
      padding: 8px 12px; }
    div.select_wrapper div.select_settings:after {
      -webkit-transform: scaleX(0);
              transform: scaleX(0); }
    div.select_wrapper div.select_settings:before {
      border: none; }
    div.select_wrapper div.select_settings:hover:before {
      border: none !important; }

.select_settings_menu {
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #7F8FA4;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 0 12px;
  min-width: 275px;
  border: 1px solid #C2D1D9; }
  .select_settings_menu option {
    padding: 4px 0;
    cursor: pointer; }

.select_settings_icon {
  font-size: 16px !important;
  margin-right: 10px;
  margin-top: 4px; }

.status_block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px; }

.status_title {
  color: #7F8FA4;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center; }
  .status_title span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px; }

.order_title {
  margin: 15px 0 10px;
  display: flex;
  justify-content: space-between; }
  .order_title a {
    text-decoration: none; }
  .order_title h5 {
    color: #01397C;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px; }

.block_sub_value {
  color: #7F8FA4;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center; }
  .block_sub_value svg {
    margin-right: 4px;
    fill: gray;
    font-size: 12px; }

.block_value_currency {
  color: #334150;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px; }
  .block_value_currency i {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-style: normal; }

.order_bottom_info {
  display: flex;
  justify-content: space-between;
  margin: 6px 0; }
  .order_bottom_info .block_value_currency {
    font-size: 23px;
    line-height: 27px;
    align-self: flex-end;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.rating_wrapper {
  display: flex;
  align-items: center; }
  .rating_wrapper > span {
    margin-left: 8px;
    color: #7F8FA4;
    font-size: 14px;
    line-height: 17px; }

.default_dialog_root .paper_custom_dialog {
  min-width: 840px;
  width: 100%; }
  .default_dialog_root .paper_custom_dialog.representation_dialog {
    min-width: 902px; }

.default_dialog_root .dialog_close_button {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  background: transparent; }

.default_dialog_root .title-dialog {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 30px; }

.default_dialog_root .descriptions {
  color: #334150;
  font-size: 16px;
  line-height: 20px; }

.default_dialog_root .btn-wrapper {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #C2D1D9;
  display: flex;
  align-items: center;
  width: 100%; }
  .default_dialog_root .btn-wrapper button, .default_dialog_root .btn-wrapper a {
    margin-right: 14px; }

.dialog-form {
  width: 100%; }

.dialog-field {
  display: flex; }
  .dialog-field .custom_input_wrapper {
    margin-right: 50px; }
  .dialog-field .check_field_wrapper {
    margin-right: 30px; }

.dialog-field-change {
  display: flex;
  flex-flow: column; }
  .dialog-field-change .custom_input_wrapper {
    margin-bottom: 20px;
    position: relative; }
    .dialog-field-change .custom_input_wrapper i {
      position: absolute;
      right: 0;
      top: 0; }

.dialog-field-representation .custom_input_wrapper {
  max-width: 100%; }

.representation-block {
  padding-top: 20px;
  display: flex; }
  .representation-block .schedule {
    display: flex;
    flex-flow: column;
    justify-content: space-between; }
    .representation-block .schedule .title {
      color: #7F8FA4;
      font-size: 12px;
      font-weight: 300;
      line-height: 15px; }
    .representation-block .schedule .schedule-day {
      display: flex;
      align-items: center; }
      .representation-block .schedule .schedule-day p {
        padding: 0 5px; }
      .representation-block .schedule .schedule-day .check_field_wrapper {
        padding-right: 15px;
        width: 55px; }

.block-change-info {
  width: 100%; }
  .block-change-info .text-info {
    display: flex;
    align-items: center;
    color: #334150;
    font-size: 16px;
    line-height: 20px; }
    .block-change-info .text-info img {
      margin-right: 7px; }

.dialog_wrapper {
  width: 100%;
  padding: 40px 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  background-color: #FFFFFF; }
  .dialog_wrapper .reviews {
    padding: 0 !important; }
    .dialog_wrapper .reviews textarea {
      max-width: inherit; }

.review_title {
  margin-top: 15px; }
  .review_title h5 {
    color: #01397C;
    font-size: 23px;
    font-weight: 600;
    line-height: 29px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.review_content {
  color: #334150;
  font-size: 14px;
  line-height: 17px;
  margin: 15px 0;
  height: 70px;
  overflow: hidden; }

.review_btn_wrapper {
  text-align: center;
  padding: 12px 0; }
  .review_btn_wrapper button {
    background-color: transparent;
    color: #0253B3;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .review_btn_wrapper button:hover {
      text-decoration: none; }

.price_title {
  margin: 12px 0 10px; }
  .price_title h5 {
    color: #01397c;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.price_btn_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14px 0 10px; }
  .price_btn_wrapper .tick_wrapper {
    color: #7f8fa4;
    font-size: 14px;
    line-height: 17px; }
    .price_btn_wrapper .tick_wrapper img {
      margin-right: 5px; }

.refresh_price_btn {
  background-color: transparent;
  color: #0253b3;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  cursor: pointer; }
  .refresh_price_btn.text-green {
    color: #16d761;
    text-decoration: none;
    cursor: default; }
    .refresh_price_btn.text-green img {
      margin-right: 5px; }
  .refresh_price_btn:hover {
    text-decoration: none; }

.delete_price_btn {
  background-color: transparent;
  color: #d0021b;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  cursor: pointer; }

.info_card.has-inner {
  cursor: pointer; }

.select_component {
  height: 100%; }
  .select_component ~ .date_wrapper {
    margin-left: 20px; }
  .select_component .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8; }
    .select_component .select__control--is-focused .indicator_active svg {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .select_component .select__control {
    height: 100%;
    border-radius: 3px;
    border: 1px solid #C2D1D9;
    font-size: 14px; }
    .select_component .select__control:hover {
      border: 1px solid #8A979E; }
    .select_component .select__control .select__placeholder {
      color: rgba(127, 143, 164, 0.5); }
    .select_component .select__control .select__single-value {
      color: #7F8FA4; }
    .select_component .select__control .select__input {
      color: #7F8FA4;
      font-size: 14px; }
      .select_component .select__control .select__input input {
        font-family: "Akrobat Regular", sans-serif !important;
        font-size: 12px; }
    .select_component .select__control .select__indicator-separator {
      background-color: rgba(101, 163, 232, 0.5); }
    .select_component .select__control .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
      .select_component .select__control .select_indicator svg {
        fill: #7F8FA4;
        font-size: 20px; }
    .select_component .select__control .select__loading-indicator span {
      background-color: #7F8FA4; }
    .select_component .select__control .select_close_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer; }
      .select_component .select__control .select_close_btn svg {
        fill: #7F8FA4;
        font-size: 14px; }
  .select_component .select__menu {
    color: #7F8FA4;
    z-index: 9999; }
    .select_component .select__menu .select__menu-list {
      font-family: "Akrobat Regular", sans-serif;
      font-size: 14px; }

.no-items {
  border-radius: 5px;
  background-color: #FFFFFF;
  display: flex;
  width: 100%;
  flex-flow: column;
  padding: 40px 0;
  align-items: center;
  margin: 30px 0 50px; }
  .no-items .item-block {
    text-align: center; }
    .no-items .item-block .box-img {
      padding-bottom: 20px; }
    .no-items .item-block .title {
      color: #334150;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 10px; }
    .no-items .item-block .descriptions {
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px; }

.info-head {
  height: 200px;
  background-color: #01397C;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px; }
  .info-head .edit_btn {
    align-self: flex-end;
    background: transparent;
    box-shadow: none;
    color: #EFF2F4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    padding: 0;
    margin-right: -5px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .info-head .edit_btn img {
      margin-left: 9px; }
    .info-head .edit_btn:hover {
      text-decoration: underline;
      background: transparent; }

.info-header {
  display: flex;
  padding-bottom: 11px; }

.info-head-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #01397C;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%; }

.info-head_content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }

.info-head_title {
  font-size: 60px;
  font-weight: 600;
  line-height: 69px; }

.info-head_contacts {
  display: flex;
  margin-top: 20px; }
  .info-head_contacts p {
    display: inline-flex;
    align-items: center;
    margin-right: 70px; }
    .info-head_contacts p svg, .info-head_contacts p img {
      margin-right: 10px; }

.search_wrapper_dashboard {
  margin: 50px 0; }
  .search_wrapper_dashboard .search_product_wrapper {
    position: relative; }
    .search_wrapper_dashboard .search_product_wrapper input {
      max-width: 50%;
      width: 740px;
      background-color: #FFFFFF;
      border-radius: 3px;
      border: 1px solid #C2D1D9;
      color: #7F8FA4;
      font-family: "Akrobat Light", sans-serif;
      font-size: 14px;
      font-weight: 300;
      padding: 0 12px;
      height: 41px;
      line-height: 41px; }
    .search_wrapper_dashboard .search_product_wrapper:after {
      content: "";
      position: absolute;
      top: 12px;
      left: 710px;
      width: 20px;
      height: 20px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAglJREFUOBGdVDtPVFEQnjl3wbAJiWKwsKCBtfAaLWhIeCSugY6EP2ADWPio/CG2JrpLg40dnQnPBN3CwgLDpWDXxsaCSEwIkJA9Z5zvLLO5QfEST7J3zsx8850zj7NMF9anrJlKkCckNC0kQ3Az8XchWnUJ1ybSSnYhJKpsxiyT3kO//0rDnoqIM3teMnMgktcDyZ2XacpneV8MAMnP9v4HEXquTlb2OjueLfdcu32zdKOfSzzG7N7CBwywiMkTlaB0bkJVzeGHSPJ46sHwRh6k+8/4be98e0/cXta0q+cxLwzHsSZevqpBT0um/0Ji2CgbWasafFhXRTjh+1Yzh8LGmogsFZGAaTwd2dQza4gJXhYju34cuhMVxytmLJSJ1IHRWs4Y1lmL+5LeL2YskgN0PY6AxQLfbXOflI+KCMx/yif9tjfpMGxQDulXasYieerPRoGxWOw1NVrFhjwvRHmVT5C5CGNaM7hLSq5+PrGLaK05LpM6S490ZOcRo0OLIY3Ljd8d2cXYazvZe//uX2QdkvYysDrhb2yGwBTfmj0R1at6kmbLNdIWozsobKwJ0tGbgKQTyJmU+eFUpXLQJcLmqo8WN1GmSW19qsXukkV2ENlq7LXu+XZYUMeMzUnsjhYWNUE6jVbrVjgOm3myP4iMsEhuN5uDfCJbHTKHf43/X7jZx53mMzD8BsAf9UyNBedWAAAAAElFTkSuQmCC) no-repeat; }

.section_link_wrapper {
  margin-bottom: 50px; }
  .section_link_wrapper a {
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .section_link_wrapper a:hover {
      text-decoration: underline; }

.section_link {
  color: #0253B3;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none; }
  .section_link img {
    margin-left: 5px; }

.dashboard-section_head {
  margin-bottom: 30px; }
  .dashboard-section_head h4 {
    color: #334150;
    font-size: 60px;
    line-height: 75px;
    font-weight: 400; }

.dashboard-section_content {
  padding: 50px 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.search_product_wrapper {
  display: block; }
  .search_product_wrapper input {
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    height: 41px;
    width: 100%;
    line-height: 41px;
    padding: 0 12px;
    color: #01397C;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    font-weight: 300; }
    .search_product_wrapper input::-webkit-input-placeholder {
      color: #7F8FA4; }
    .search_product_wrapper input::-moz-placeholder {
      color: #7F8FA4; }
    .search_product_wrapper input::-ms-input-placeholder {
      color: #7F8FA4; }
    .search_product_wrapper input::placeholder {
      color: #7F8FA4; }

body #products_popper {
  z-index: 1500; }
  body #products_popper .popper_wrapper {
    border: 1px solid #C2D1D9;
    max-height: 400px;
    overflow: auto;
    width: 740px; }

body #my_orders_popper .popper_wrapper {
  border: 1px solid #C2D1D9;
  max-height: 400px;
  overflow: auto;
  width: 471px; }

.search_products_list > :first-child {
  padding-top: 10px; }

.search_products_list > :last-child {
  border-bottom: none;
  padding-bottom: 10px; }

.search_product_item {
  padding: 15px 0;
  border-bottom: 1px solid #C2D1D9;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .search_product_item:hover {
    background-color: rgba(164, 231, 255, 0.1); }

.search_product_item_head {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .search_product_item_head h4 {
    color: #01397C;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .search_product_item_head span {
    color: #7F8FA4;
    font-size: 12px;
    line-height: 15px; }
  .search_product_item_head .highlight {
    background: #a4e7ff; }

.search_product_item_info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  .search_product_item_info > div {
    width: 20%; }
  .search_product_item_info > :last-child {
    text-align: right;
    font-family: Roboto, sans-serif;
    line-height: 27px;
    font-size: 23px; }
  .search_product_item_info .block_value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.message_popper .message_tooltip {
  background-color: #29323c;
  font-size: 10px;
  word-break: break-word;
  font-family: "Akrobat Regular", sans-serif; }

.message_popper .error_message_tooltip {
  background-color: #ff0000;
  font-size: 12px;
  font-family: "Akrobat Regular", sans-serif; }

body .auth_tooltip_popper {
  top: 8px !important;
  left: 9px; }

.custom_input_wrapper {
  width: 100%;
  max-width: 300px; }
  .custom_input_wrapper .custom_input_label {
    font-family: "Akrobat Regular", sans-serif;
    color: #c2d1d9;
    font-size: 14px;
    -webkit-transform: translate(11px, 13px) scale(1);
            transform: translate(11px, 13px) scale(1);
    font-weight: 400; }
    .custom_input_wrapper .custom_input_label.custom_input_label_focused {
      color: rgba(255, 255, 255, 0.72); }
    .custom_input_wrapper .custom_input_label.custom_input_label_active {
      color: #01397c;
      -webkit-transform: translate(13px, -6px) scale(0.75);
              transform: translate(13px, -6px) scale(0.75); }
    .custom_input_wrapper .custom_input_label.custom_input_label_error {
      color: #ff0000; }
  .custom_input_wrapper .custom_input {
    font-family: "Akrobat Regular", sans-serif;
    background-color: #ffffff;
    color: #01397c;
    font-size: 14px;
    border-radius: 4px;
    max-height: 40px; }
    .custom_input_wrapper .custom_input:hover .custom_input_outline {
      border-color: #c2d1d9 !important; }
    .custom_input_wrapper .custom_input:focus {
      background-color: #ffffff; }
    .custom_input_wrapper .custom_input input {
      padding: 12px 14px; }
    .custom_input_wrapper .custom_input .custom_input_outline {
      border-color: #c2d1d9; }
    .custom_input_wrapper .custom_input.custom_input_focused {
      background-color: #ffffff; }
      .custom_input_wrapper .custom_input.custom_input_focused .custom_input_outline {
        border: 1px solid #01397c; }
    .custom_input_wrapper .custom_input.custom_input_error .custom_input_outline, .custom_input_wrapper .custom_input.custom_input_error:hover .custom_input_outline {
      border-color: #ff0000 !important; }
    .custom_input_wrapper .custom_input.custom_input_disabled {
      color: #334150; }
  .custom_input_wrapper .custom_input_adorned_end {
    padding-right: 5px; }
    .custom_input_wrapper .custom_input_adorned_end > div:last-of-type {
      margin-right: 8px; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type span {
        color: #c2d1d9;
        right: 12px;
        position: absolute; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type span.error_empty {
          right: 0;
          width: 100%;
          height: 15px;
          top: -10px; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type svg {
        fill: #ff0000;
        cursor: pointer; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn {
        padding: 0;
        width: 25px;
        height: 25px; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn:hover {
          background-color: transparent; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn svg {
          fill: #c2d1d9; }
  .custom_input_wrapper .custom_input_adorned_start > div:first-of-type {
    margin-right: 0; }
    .custom_input_wrapper .custom_input_adorned_start > div:first-of-type span {
      color: #c2d1d9; }

.check_field_wrapper {
  color: transparent;
  display: flex;
  margin: 7px 0; }
  .check_field_wrapper label {
    margin: 0; }
    .check_field_wrapper label > :first-child > :last-child > :first-child {
      width: 17px !important;
      height: 17px !important;
      top: 1px !important;
      left: 1px !important; }
    .check_field_wrapper label > :last-child {
      color: #334150;
      font-size: 14px;
      margin-left: 5px;
      font-weight: 400;
      font-family: "Akrobat Regular", sans-serif; }
  .check_field_wrapper .custom_check {
    padding: 0; }

.page-animation-enter {
  opacity: 0.01; }

.page-animation-enter.page-animation-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.page-animation-leave {
  opacity: 1; }

.page-animation-leave.page-animation-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.page-animation-appear {
  opacity: 0.01; }

.page-animation-appear.page-animation-appear-active {
  opacity: 1;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; }

.snack_root {
  margin-bottom: 20px; }
  .snack_root > div {
    padding: 5px 12px;
    border-radius: 5px;
    color: #334150;
    font-size: 16px;
    font-weight: 600;
    font-family: "Akrobat SemiBold", sans-serif;
    min-width: 400px; }
    .snack_root > div > :first-child {
      padding: 8px 0 10px; }
      .snack_root > div > :first-child span img {
        margin-right: 8px; }
    .snack_root > div button {
      padding: 5px; }
  .snack_root.error_snack div {
    background-color: #FF4D62;
    font-family: "Inter", sans-serif; }
    .snack_root.error_snack div svg {
      fill: #ffffff; }
  .snack_root.success_snack > div {
    background-color: #ffffff; }
    .snack_root.success_snack > div svg {
      fill: #C2D1D9; }

.custom_time_field > div > div {
  border: 1px solid #C2D1D9;
  border-radius: 3px;
  height: 40px;
  min-width: 53px;
  font-family: "Akrobat Light", sans-serif; }
  .custom_time_field > div > div input[type="time"]::-webkit-clear-button {
    display: none; }
  .custom_time_field > div > div input {
    padding: 0 10px;
    font-size: 14px;
    color: #7F8FA4;
    text-align: center; }
    .custom_time_field > div > div input::-webkit-inner-spin-button, .custom_time_field > div > div input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .custom_time_field > div > div input::-webkit-search-decoration, .custom_time_field > div > div input::-webkit-search-cancel-button, .custom_time_field > div > div input::-webkit-search-results-button, .custom_time_field > div > div input::-webkit-search-results-decoration {
      display: none; }
    .custom_time_field > div > div input::-ms-clear {
      display: none;
      width: 0;
      height: 0; }
    .custom_time_field > div > div input::-ms-reveal {
      display: none;
      width: 0;
      height: 0; }
  .custom_time_field > div > div:before, .custom_time_field > div > div:after {
    display: none; }

.content-block-wrapper {
  background: #fff;
  padding: 40px 50px;
  margin-bottom: 50px; }
  .content-block-wrapper .title-info {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .content-block-wrapper .title-info h3 {
      font-weight: normal;
      color: #334150;
      font-size: 32px;
      line-height: 40px; }

.info_company_block {
  position: relative; }
  .info_company_block .add_btn {
    position: absolute;
    left: 400px;
    top: 212px; }

.info-company .title {
  color: #334150;
  font-size: 32px;
  line-height: 40px; }

.info-company .field-block {
  align-items: flex-end;
  display: flex; }

.info-company .custom_input_wrapper {
  margin: 41px 49px 0 0; }

.change_password_fields > :last-child {
  margin: 0; }

.check_wrapper {
  padding: 7px 0 23px;
  border-bottom: 1px solid #C2D1D9;
  margin-bottom: 30px; }

.contacts {
  padding-top: 20px; }
  .contacts .block_label {
    padding-bottom: 7px; }
  .contacts .contacts-info {
    display: flex;
    align-items: center; }
    .contacts .contacts-info .info {
      color: #334150;
      font-size: 23px;
      padding-right: 20px; }
    .contacts .contacts-info .info-social {
      display: flex;
      align-items: center; }
      .contacts .contacts-info .info-social img {
        margin-right: 8px; }
    .contacts .contacts-info .contacts-info-left {
      display: flex;
      min-width: 250px; }
    .contacts .contacts-info .contacts-info-right {
      display: flex; }
      .contacts .contacts-info .contacts-info-right button {
        margin-right: 20px; }

.content-block-wrapper .contacts:last-child {
  border-bottom: 1px solid #C2D1D9;
  padding-bottom: 30px;
  margin-bottom: 30px; }

.schedule {
  margin-right: 30px; }
  .schedule .block_label {
    margin-bottom: 15px;
    display: block; }

.schedule-time {
  margin-bottom: 15px; }

.map_wrapper {
  width: 537px;
  height: 365px;
  position: relative;
  overflow: hidden; }

.schedule_map_wrapper {
  width: 537px;
  height: 240px;
  position: relative;
  overflow: hidden; }

.expansion-title > p {
  font-size: 23px;
  cursor: pointer;
  margin-right: 30px; }
  .expansion-title > p button {
    padding: 5px; }
    .expansion-title > p button svg {
      font-size: 18px; }

.time_day {
  color: #334150;
  font-family: "Akrobat SemiBold", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-right: 15px;
  width: 15px;
  display: inline-block; }

.time_value {
  color: #334150;
  font-size: 14px;
  line-height: 17px;
  width: 15px; }

.expansion-block {
  margin-top: 20px; }

.schedule-time_inactive .time_day, .schedule-time_inactive .time_value {
  color: #7F8FA4; }

.info_section_no_items {
  border-bottom: 1px solid #C2D1D9;
  padding: 20px 0 30px;
  margin-bottom: 30px; }

.info_section_loader {
  padding: 40px 0; }

.info_company_loader_wrapper {
  height: 324px; }

.loader_wrapper_center {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50vh; }
  .loader_wrapper_center.loader_wrapper_center_dashboard {
    height: 300px; }

.custom_count_field {
  display: inline-block; }

.count_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(194, 209, 217, 0.3) !important;
  border-radius: 3px;
  height: 40px; }
  .count_field.count_field_disabled {
    opacity: 0.7; }
  .count_field input {
    font-family: "Akrobat Regular", sans-serif;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    color: #334150;
    background-color: transparent;
    width: 30px; }
    .count_field input:disabled {
      color: #334150; }
  .count_field button {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    font-size: 30px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .count_field button:disabled {
      opacity: 0.7;
      cursor: default; }
      .count_field button:disabled:hover {
        background-color: transparent; }
    .count_field button:hover {
      background-color: rgba(51, 65, 80, 0.1); }
    .count_field button svg {
      font-size: 20px;
      fill: #334150; }

.comment-orders {
  background: #fff;
  padding: 40px 50px;
  margin-bottom: 50px; }
  .comment-orders .title {
    color: #334150;
    font-size: 32px;
    padding-bottom: 20px; }

.btn_hide {
  color: #0253B3;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  background: transparent;
  margin-bottom: 20px; }
  .btn_hide:hover {
    opacity: 0.7; }

.comment {
  display: flex;
  padding-bottom: 20px;
  flex-flow: column; }
  .comment .comment-name {
    color: #01397C;
    font-size: 16px;
    font-weight: 600;
    padding-right: 20px; }
  .comment .comment-info {
    display: flex;
    align-items: center;
    padding-bottom: 5px; }
  .comment .comment-time {
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300; }
  .comment .descriptions {
    color: #334150;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px; }

.comment-text {
  width: 651px;
  height: auto;
  border: 1px solid #C2D1D9;
  border-radius: 3px;
  padding: 13px 10px;
  resize: none;
  font-family: "Akrobat Regular", sans-serif;
  margin-bottom: 5px;
  overflow: hidden; }
  .comment-text::-webkit-input-placeholder {
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px; }
  .comment-text::-moz-placeholder {
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px; }
  .comment-text::-ms-input-placeholder {
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px; }
  .comment-text::placeholder {
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px; }

.comments-wrapper > div.comment {
  text-align: justify;
  height: inherit;
  overflow: auto;
  opacity: 1;
  width: 651px; }

.comments-wrapper.comments-wrapper-last > div.comment {
  height: 0;
  overflow: hidden;
  opacity: 0;
  padding-bottom: 0; }

.comments-wrapper.comments-wrapper-last > div.comment:last-child {
  height: inherit;
  overflow: auto;
  opacity: 1;
  padding-bottom: 20px; }

.preloader_wrapper {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 9999; }
  .preloader_wrapper > div {
    height: 3px;
    background-color: #01397C; }
    .preloader_wrapper > div > div {
      background-color: #ffffff; }

.order_section_title {
  color: #334150;
  font-size: 32px;
  line-height: 40px; }

.add_order_block {
  margin: 30px 0; }
  .add_order_block .custom_input_wrapper {
    margin-right: 50px; }
  .add_order_block > :last-child {
    margin: 0; }

.edit_btn_wrapper {
  margin-top: 30px; }
  .edit_btn_wrapper > div {
    display: flex;
    align-items: center; }
    .edit_btn_wrapper > div > :last-child {
      margin: 0; }
  .edit_btn_wrapper .default_button_wrapper {
    margin-right: 15px; }

.download_xlsx {
  color: #0253b3;
  font-size: 14px;
  text-decoration: underline;
  background: none;
  display: block;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .download_xlsx:hover {
    text-decoration: none;
    opacity: 0.7;
    cursor: pointer; }

.add_order_info {
  color: #7f8fa4;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  margin: 20px 0 30px; }

.orders_summary {
  margin: 30px 0; }
  .orders_summary > div {
    margin-right: 100px; }
  .orders_summary > :last-child {
    margin: 5px 0 0; }
  .orders_summary div .block_label {
    display: block;
    margin-bottom: 15px; }
  .orders_summary div .block_value_currency {
    font-size: 23px; }

.order_product_item {
  margin: 30px 0;
  display: flex; }
  .order_product_item .custom_input_wrapper {
    max-width: 50%;
    margin-right: 30px; }
  .order_product_item .red_btn {
    margin-left: 40px; }
  .order_product_item.order_product_item_error .custom_count_field .count_field {
    border-color: #ff0000; }

.auth_error.error_after_button {
  color: #ff0000;
  font-size: 12px;
  width: auto;
  position: static;
  display: flex;
  align-items: center; }
  .auth_error.error_after_button svg {
    margin-right: 5px; }

.add_product .search_product_wrapper {
  width: 100%;
  margin-right: 50px; }

.pagination_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 57px;
  background-color: transparent; }

.pagination {
  display: flex;
  align-items: center; }
  .pagination > :first-child {
    margin-left: 0; }
  .pagination > :last-child {
    margin-right: 0; }
  .pagination .pagin_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin: 0 2px; }
    .pagination .pagin_item.pagin_item_active a {
      background-color: #0253B3;
      border-radius: 2px;
      color: #ffffff;
      opacity: 1;
      box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.08); }
      .pagination .pagin_item.pagin_item_active a:hover {
        color: #ffffff; }
    .pagination .pagin_item a {
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      color: #0253B3;
      font-family: "Akrobat Regular", sans-serif;
      font-size: 14px;
      line-height: 28px;
      text-decoration: none;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out; }
      .pagination .pagin_item a:hover {
        text-decoration: none;
        text-shadow: 0 0 15px #000000; }
  .pagination .pagin_prev, .pagination .pagin_next {
    margin: 0; }
    .pagination .pagin_prev.disabled a, .pagination .pagin_next.disabled a {
      opacity: 0.5;
      cursor: not-allowed; }
    .pagination .pagin_prev a, .pagination .pagin_next a {
      display: flex;
      align-items: center;
      justify-content: center; }
      .pagination .pagin_prev a svg, .pagination .pagin_next a svg {
        font-size: 20px;
        fill: #0253B3; }

.content-block {
  background: #fff;
  padding: 10px 50px 0; }
  .content-block .no-items {
    padding-top: 0; }
  .content-block.content-block-reviews {
    margin-bottom: 30px; }

.info-time {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .info-time img {
    margin-right: 5px; }
  .info-time span {
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px; }

.reviews-wrapper .name {
  color: #01397C;
  font-size: 23px;
  font-weight: 600;
  line-height: 29px; }

.rating {
  display: flex;
  align-items: center;
  padding-bottom: 15px; }
  .rating span {
    color: #7F8FA4;
    font-size: 14px;
    padding-left: 5px; }

.reviews:last-of-type {
  border: none;
  padding: 30px 0 40px; }

.reviews {
  padding: 30px 0 30px;
  border-bottom: 1px solid #C2D1D9; }
  .reviews .rating_wrapper {
    margin-bottom: 10px; }
  .reviews .descriptions {
    color: #334150;
    font-size: 14px;
    line-height: 17px;
    padding: 0 0 15px;
    word-break: break-all;
    width: 650px; }
    .reviews .descriptions:last-of-type {
      padding-bottom: 0;
      word-break: break-all; }
    .reviews .descriptions span {
      font-family: "Akrobat SemiBold", sans-serif; }
  .reviews textarea {
    font-family: "Akrobat Regular", sans-serif;
    resize: none;
    box-sizing: border-box;
    height: 60px;
    font-size: 14px;
    max-width: 650px;
    width: 100%;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    padding: 12px 11px;
    margin-bottom: 10px; }
    .reviews textarea::-webkit-input-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300; }
    .reviews textarea::-moz-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300; }
    .reviews textarea::-ms-input-placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300; }
    .reviews textarea::placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300; }
  .reviews .default_button_wrapper .default_button.default_button_contained {
    background-color: #0253B3;
    color: #ffffff;
    border: none; }

.basket_page .title-block h1 {
  margin-top: 50px; }

.basket {
  padding: 30px 50px 40px;
  margin-bottom: 65px;
  background: #fff; }
  .basket .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .basket .transactions_columns .table_row > :first-child {
    width: 4%; }
  .basket .transactions_columns .table_row > :nth-child(2) {
    width: 13%; }
  .basket .transactions_columns .table_row > :nth-child(3) {
    width: 13%; }
  .basket .transactions_columns .table_row > :nth-child(4) {
    width: 13%; }
    .basket .transactions_columns .table_row > :nth-child(4) div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 95%; }
  .basket .transactions_columns .table_row > :nth-child(5) {
    width: 13%; }
  .basket .transactions_columns .table_row > :nth-child(6) {
    width: 13%; }
  .basket .transactions_columns .table_row > :nth-child(7) {
    width: 13%; }
  .basket .transactions_columns .table_row > :nth-child(8) {
    width: 13%; }
  .basket .transactions_columns .table_row > :last-child {
    width: 5%;
    display: flex;
    justify-content: flex-end; }
  .basket .table_container .table_header .table_header {
    display: flex; }
  .basket .table_header .row_item {
    color: #7F8FA4;
    font-size: 12px; }
  .basket .table_body .row_item {
    color: #334150;
    font-size: 16px; }
  .basket .row_item_disable {
    opacity: 0.7; }
  .basket .table_row {
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .basket .basket_table_btn {
    align-items: center;
    display: flex; }
    .basket .basket_table_btn button {
      background: transparent;
      cursor: pointer;
      margin-left: 20px; }
  .basket .amount {
    border: 1px solid rgba(194, 209, 217, 0.3);
    border-radius: 3px;
    height: 41px;
    width: 93px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px; }
    .basket .amount button {
      cursor: pointer;
      background: transparent;
      height: 100%;
      display: flex;
      width: 35px;
      position: relative; }
    .basket .amount .minus:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 10px;
      background: #334150;
      top: calc(50% - 1px);
      left: calc(50% - 5px);
      opacity: 0.3; }
    .basket .amount .plus:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 10px;
      background: #334150;
      top: calc(50% - 0.5px);
      left: calc(50% - 5px); }
    .basket .amount .plus:after {
      position: absolute;
      content: "";
      height: 10px;
      width: 1px;
      background: #334150;
      top: calc(50% - 5px);
      left: calc(50% - 0.5px); }

.basket_price {
  padding-top: 20px; }
  .basket_price .available_funds {
    color: #334150;
    font-size: 16px;
    padding-bottom: 10px; }
  .basket_price .all_price {
    color: #334150;
    font-size: 32px;
    line-height: 38px; }
    .basket_price .all_price span {
      font-family: 'Akrobat Bold', sans-serif;
      font-weight: 500; }

.ru_value {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500; }

.basket_btn_wrapper {
  padding-top: 30px;
  display: flex; }
  .basket_btn_wrapper .default_button_wrapper {
    margin-right: 15px; }

.weight_bold {
  font-weight: 600; }

.basket_commends {
  box-sizing: border-box;
  height: 59px;
  width: 100%;
  border: 1px solid #C2D1D9;
  border-radius: 3px;
  resize: none;
  padding: 12px;
  color: #334150;
  font-size: 14px; }
  .basket_commends::-webkit-input-placeholder {
    color: #7F8FA4; }
  .basket_commends::-moz-placeholder {
    color: #7F8FA4; }
  .basket_commends::-ms-input-placeholder {
    color: #7F8FA4; }
  .basket_commends::placeholder {
    color: #7F8FA4; }

.basket_no_items {
  padding: 140px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center; }
  .basket_no_items .text {
    padding: 20px 0 10px;
    color: #334150;
    font-size: 16px;
    line-height: 20px; }
  .basket_no_items span {
    color: #7F8FA4;
    font-size: 14px; }

.info-success-buy {
  display: flex;
  min-width: 420px;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 20px;
  position: fixed;
  left: 50%;
  bottom: 10%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: #fff;
  z-index: 9999999;
  border-radius: 5px; }
  .info-success-buy > div {
    display: flex;
    flex-flow: row;
    align-items: center; }
    .info-success-buy > div > p {
      color: #334150;
      font-family: Akrobat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0; }
    .info-success-buy > div > span {
      margin-right: 5px; }
      .info-success-buy > div > span > img {
        margin-top: -2.5px; }
  .info-success-buy span.close {
    cursor: pointer; }

button:disabled {
  background: grey;
  opacity: 0.65;
  cursor: not-allowed; }

.my_orders {
  padding-top: 10px; }
  .my_orders .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .my_orders .transactions_columns .table_row > :first-child {
    width: 15%; }
  .my_orders .transactions_columns .table_row > :nth-child(2) {
    width: 20%; }
  .my_orders .transactions_columns .table_row > :nth-child(3) {
    width: 15%; }
  .my_orders .transactions_columns .table_row > :nth-child(4) {
    font-size: 20px;
    width: 20%; }
    .my_orders .transactions_columns .table_row > :nth-child(4) > div {
      font-weight: 600; }
  .my_orders .transactions_columns .table_row > :nth-child(5) {
    width: 15%;
    display: flex; }
  .my_orders .transactions_columns .table_row > :last-child {
    width: 15%;
    justify-content: flex-end;
    display: flex; }
    .my_orders .transactions_columns .table_row > :last-child button {
      cursor: pointer;
      background: transparent;
      margin-right: 30px; }
  .my_orders .amount {
    width: 93px;
    border: 1px solid rgba(194, 209, 217, 0.3);
    border-radius: 3px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .my_orders .amount button {
      cursor: pointer;
      background: transparent;
      height: 100%;
      display: flex;
      width: 35px;
      position: relative; }
    .my_orders .amount .minus:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 10px;
      background: #334150;
      top: calc(50% - 1px);
      left: calc(50% - 5px);
      opacity: 0.3; }
    .my_orders .amount .plus:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 10px;
      background: #334150;
      top: calc(50% - 0.5px);
      left: calc(50% - 5px); }
    .my_orders .amount .plus:after {
      position: absolute;
      content: "";
      height: 10px;
      width: 1px;
      background: #334150;
      top: calc(50% - 5px);
      left: calc(50% - 0.5px); }
  .my_orders .pagination_wrapper {
    padding: 30px 0 0 0; }

.descriptions_row {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px; }

.my_orders_search {
  box-sizing: border-box;
  position: relative;
  display: inline-block; }
  .my_orders_search input {
    font-family: "Akrobat Regular", sans-serif;
    border-radius: 3px;
    padding: 0 35px 0px 12px;
    height: 41px;
    width: 660px;
    border: 1px solid #C2D1D9;
    color: #334150;
    font-size: 14px; }
    .my_orders_search input::-webkit-input-placeholder {
      color: #7F8FA4; }
    .my_orders_search input::-moz-placeholder {
      color: #7F8FA4; }
    .my_orders_search input::-ms-input-placeholder {
      color: #7F8FA4; }
    .my_orders_search input::placeholder {
      color: #7F8FA4; }
  .my_orders_search .btn_search {
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: calc(50% - 9px);
    background: transparent; }

.no_item_orders {
  padding: 100px 0 70px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center; }
  .no_item_orders .title_no_item {
    padding: 20px 0 10px 0;
    color: #334150;
    font-size: 16px; }
  .no_item_orders .descriptions_no_item {
    color: #7F8FA4;
    font-size: 14px; }

.order-dialog-btn .default_button_wrapper {
  margin: 0 !important; }

.custom_count_field {
  display: inline-block; }

.count_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  height: 40px;
  width: 93px; }
  .count_field.count_field_disabled {
    opacity: 0.7; }
  .count_field input[type=number]::-webkit-outer-spin-button,
  .count_field input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .count_field input[type="number"] {
    -moz-appearance: textfield; }
  .count_field input {
    font-family: "Akrobat Regular", sans-serif;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    color: #334150;
    background-color: transparent;
    width: 30px; }
    .count_field input:disabled {
      color: #334150; }
  .count_field button {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    font-size: 30px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .count_field button:disabled {
      opacity: 0.7;
      cursor: default; }
      .count_field button:disabled:hover {
        background-color: transparent; }
    .count_field button:hover {
      background-color: rgba(51, 65, 80, 0.1); }
    .count_field button svg {
      font-size: 20px;
      fill: #334150; }

.delete_border {
  border-bottom: none; }

.my_orders {
  padding-top: 10px; }
  .my_orders .transactions_columns {
    padding-bottom: 40px; }
    .my_orders .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center; }
    .my_orders .transactions_columns .table_row > :first-child {
      width: 13%; }
    .my_orders .transactions_columns .table_row > :nth-child(2) {
      width: 13%; }
    .my_orders .transactions_columns .table_row > :nth-child(3) {
      width: 13%; }
    .my_orders .transactions_columns .table_row > :nth-child(4) {
      width: 14%;
      display: flex; }
    .my_orders .transactions_columns .table_row > :nth-child(5) {
      width: 14%; }
    .my_orders .transactions_columns .table_row > :nth-child(6) {
      width: 14%; }
    .my_orders .transactions_columns .table_row > :nth-child(7) {
      width: 14%; }
    .my_orders .transactions_columns .table_row > :last-child {
      width: 5%;
      justify-content: flex-end;
      display: flex; }
      .my_orders .transactions_columns .table_row > :last-child button {
        cursor: pointer;
        background: transparent; }
    .my_orders .transactions_columns > .test {
      overflow: hidden;
      max-height: 219px; }
      .my_orders .transactions_columns > .test.isShow {
        max-height: 100%; }
    .my_orders .transactions_columns > .testOriginal {
      overflow: hidden;
      max-height: 438px; }
      .my_orders .transactions_columns > .testOriginal.isShow {
        max-height: 100%; }
    .my_orders .transactions_columns > .btn_hide {
      margin-top: 20px; }
  .my_orders .amount {
    width: 93px;
    border: 1px solid rgba(194, 209, 217, 0.3);
    border-radius: 3px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .my_orders .amount button {
      cursor: pointer;
      background: transparent;
      height: 100%;
      display: flex;
      width: 35px;
      position: relative; }
    .my_orders .amount .minus:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 10px;
      background: #334150;
      top: calc(50% - 1px);
      left: calc(50% - 5px);
      opacity: 0.3; }
    .my_orders .amount .plus:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 10px;
      background: #334150;
      top: calc(50% - 0.5px);
      left: calc(50% - 5px); }
    .my_orders .amount .plus:after {
      position: absolute;
      content: "";
      height: 10px;
      width: 1px;
      background: #334150;
      top: calc(50% - 5px);
      left: calc(50% - 0.5px); }
  .my_orders .pagination_wrapper {
    padding: 30px 0 0 0; }

.name_block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #7F8FA4;
  font-size: 23px;
  line-height: 29px; }
  .name_block span {
    color: #334150;
    font-weight: 600; }
  .name_block b {
    margin: 0 15px; }
  .name_block .original {
    margin-left: 30px;
    padding: 1px 8px;
    border: 1px solid #2CC197;
    color: #2CC197;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase; }
  .name_block .analogue {
    margin-left: 30px;
    padding: 1px 8px;
    border: 1px solid #249AF3;
    color: #249AF3;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase; }

.all_goods {
  margin-top: 20px;
  background: transparent;
  color: #0253B3;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer; }
  .all_goods:hover {
    opacity: 0.8; }

.my_orders_search {
  box-sizing: border-box;
  position: relative;
  display: inline-block; }
  .my_orders_search input {
    border-radius: 3px;
    padding: 0 35px 0px 12px;
    height: 41px;
    width: 471px;
    border: 1px solid #C2D1D9;
    color: #334150;
    font-size: 14px; }
    .my_orders_search input::-webkit-input-placeholder {
      color: #7F8FA4; }
    .my_orders_search input::-moz-placeholder {
      color: #7F8FA4; }
    .my_orders_search input::-ms-input-placeholder {
      color: #7F8FA4; }
    .my_orders_search input::placeholder {
      color: #7F8FA4; }
  .my_orders_search .btn_search {
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: calc(50% - 9px);
    background: transparent; }
  .my_orders_search .search_products_list > :first-child {
    padding-top: 10px; }
  .my_orders_search .search_products_list > :last-child {
    border-bottom: none;
    padding-bottom: 10px; }
  .my_orders_search .my_orders_ul {
    width: 410px; }
    .my_orders_search .my_orders_ul li {
      box-sizing: border-box;
      border-bottom: 1px solid #C2D1D9;
      padding: 15px 0px !important;
      background: #fff !important;
      cursor: pointer; }
    .my_orders_search .my_orders_ul > :last-child {
      border-bottom: none; }

.purchases_tabs .my_orders .table_row {
  height: 73px; }

.wrapper_orders_block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding-bottom: 40px; }

.wrapper_orders_block:last-of-type {
  border: none;
  padding-bottom: 0; }

.my_orders_table {
  padding-top: 30px; }
  .my_orders_table hr {
    margin: 30px 0 0; }

.no_item_orders {
  padding: 100px 0 70px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center; }
  .no_item_orders .title_no_item {
    padding: 20px 0 10px 0;
    color: #334150;
    font-size: 16px; }
  .no_item_orders .descriptions_no_item {
    color: #7F8FA4;
    font-size: 14px; }

.order-dialog-btn .default_button_wrapper {
  text-transform: uppercase;
  margin: 0 !important; }
  .order-dialog-btn .default_button_wrapper > span {
    text-transform: uppercase; }

.delete_border {
  border-bottom: none !important; }

.search_product_item {
  padding: 15px 0;
  border-bottom: 1px solid #C2D1D9;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .search_product_item:hover {
    background-color: rgba(164, 231, 255, 0.1); }

.search_product_item_head {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .search_product_item_head h4 {
    color: #01397C;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .search_product_item_head span {
    color: #7F8FA4;
    font-size: 12px;
    line-height: 15px; }
  .search_product_item_head .highlight {
    background: #a4e7ff; }

.my_orders_li {
  display: flex;
  justify-content: space-between; }
  .my_orders_li p {
    color: #7F8FA4;
    font-size: 14px;
    max-width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .my_orders_li p > span:first-of-type {
      color: #0A59B6; }
  .my_orders_li .my_orders_dot {
    margin-left: 10px;
    margin-right: 10px; }

.order_item {
  margin-top: -20px; }
  .order_item .my_orders_table {
    padding: 0; }
  .order_item .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .order_item .transactions_columns .table_body .table_row .row_orders > :nth-child(2) {
    font-weight: 600; }
  .order_item .transactions_columns .table_body .table_row .row_orders > :nth-child(6) {
    font-weight: 600; }
  .order_item .transactions_columns .table_row {
    align-items: flex-start;
    height: auto;
    flex-flow: column; }
    .order_item .transactions_columns .table_row .row_orders {
      width: 100%;
      display: flex;
      height: 40px;
      align-items: center; }
      .order_item .transactions_columns .table_row .row_orders > :first-child {
        width: 10%; }
        .order_item .transactions_columns .table_row .row_orders > :first-child > div {
          padding-left: 12px;
          font-weight: 600; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(2) {
        width: 6%; }
        .order_item .transactions_columns .table_row .row_orders > :nth-child(2) > div {
          font-weight: 600; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(3) {
        width: 15%; }
        .order_item .transactions_columns .table_row .row_orders > :nth-child(3) > div:first-of-type {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 90%;
          font-weight: 600; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(4) {
        width: 5%;
        display: flex; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(5) {
        width: 5%; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(6) {
        width: 5%;
        font-weight: 400 !important; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(7) {
        width: 5%; }
        .order_item .transactions_columns .table_row .row_orders > :nth-child(7) .ru_value {
          font-weight: 500; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(8) {
        width: 9%; }
        .order_item .transactions_columns .table_row .row_orders > :nth-child(8) > div {
          font-weight: 600; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(9) {
        width: 9%; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(10) {
        width: 10%; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(11) {
        width: 14%; }
        .order_item .transactions_columns .table_row .row_orders > :nth-child(11) > div:first-of-type {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 90%; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(12) {
        width: 7%; }
        .order_item .transactions_columns .table_row .row_orders > :nth-child(12) > div {
          font-weight: 600; }
      .order_item .transactions_columns .table_row .row_orders > :nth-child(13) {
        width: 5%; }
  .order_item .info_items {
    padding: -1px 0 20px;
    display: flex;
    width: 100%;
    flex-flow: column; }
    .order_item .info_items .name {
      display: flex;
      padding-bottom: 20px;
      align-items: center; }
      .order_item .info_items .name div {
        padding-right: 10px;
        color: #334150;
        font-size: 16px;
        font-weight: 600; }
      .order_item .info_items .name span {
        color: #7f8fa4;
        font-size: 12px; }
    .order_item .info_items .descriptions {
      padding-bottom: 10px;
      color: #7f8fa4;
      font-size: 12px; }
    .order_item .info_items .comment {
      color: #334150;
      font-size: 16px; }
  .order_item .block_status {
    display: flex;
    align-items: center; }
  .order_item .status_orders {
    height: 6px;
    width: 6px;
    display: flex;
    border-radius: 50%;
    margin-right: 5px; }
  .order_item .blue {
    background: #249af3; }
  .order_item .green {
    background: #16d761; }
  .order_item .red {
    background: #d0021b; }
  .order_item .yellow {
    background: #d5dc00; }
  .order_item .pagination_wrapper {
    padding: 30px 0 0 0; }

.btn_arrow_up {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.filter-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  .filter-wrapper .select_wrapper {
    width: 200px; }

.tooltip {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  max-width: 250px;
  font-size: 16px !important;
  font-weight: 600;
  word-wrap: break-word; }

.date_picker_wrapper {
  box-sizing: border-box;
  margin-left: 30px;
  display: flex;
  justify-content: space-between; }
  .date_picker_wrapper .date_picker_inner {
    box-sizing: border-box;
    border: 1px solid #c2d1d9;
    border-radius: 3px;
    background-color: #ffffff;
    width: 160px;
    height: 41px;
    display: flex;
    align-items: center;
    padding: 0 12px; }
    .date_picker_wrapper .date_picker_inner > div {
      width: 100%; }
    .date_picker_wrapper .date_picker_inner .react-datepicker__tab-loop {
      width: 0; }
    .date_picker_wrapper .date_picker_inner p {
      color: #7f8fa4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px; }
    .date_picker_wrapper .date_picker_inner input {
      color: #334150;
      height: 37px;
      cursor: pointer;
      font-family: "Akrobat", sans-serif;
      font-size: 14px;
      padding-left: 20px;
      width: 100%; }
  .date_picker_wrapper .date_picker_inner:first-of-type {
    margin-right: 13px; }

.time_separator {
  font-size: 16px;
  color: #334150;
  font-family: "Akrobat Bold";
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5; }

.red-status {
  background-color: rgba(208, 2, 27, 0.1); }

.green-status {
  background-color: rgba(22, 215, 97, 0.1); }

.yellow-status {
  background-color: rgba(215, 215, 22, 0.1); }

.confirmed-status {
  background-color: rgba(22, 64, 215, 0.1); }

.requests .add_btn img {
  margin: 0 10px 0 0; }

.requests .requests_table {
  padding: 0; }

.requests .transactions_columns .table_header .row_item {
  display: flex;
  align-items: center; }

.requests .transactions_columns .table_row {
  align-items: flex-start;
  height: auto;
  flex-flow: column; }
  .requests .transactions_columns .table_row .row_orders {
    cursor: pointer;
    width: 100%;
    display: flex;
    height: 60px;
    align-items: center; }
    .requests .transactions_columns .table_row .row_orders > :first-child {
      width: 30%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(2) {
      width: 15%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(3) {
      width: 15%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(4) {
      width: 17%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(5) {
      width: 17%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(6) {
      width: 17%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(7) {
      width: 10%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(8) {
      width: 16%; }

.requests .info_items {
  padding: 0px 0 20px;
  display: flex;
  width: 100%;
  flex-flow: column; }
  .requests .info_items .descriptions {
    padding-bottom: 10px;
    color: #7F8FA4;
    font-size: 12px; }
  .requests .info_items .comment {
    color: #334150;
    font-size: 16px; }

.requests .block_status {
  display: flex;
  align-items: center; }

.requests .status_orders {
  height: 6px;
  width: 6px;
  display: flex;
  border-radius: 50%;
  margin-right: 5px; }

.requests .blue {
  background: #249AF3; }

.requests .green {
  background: #16D761; }

.requests .red {
  background: #D0021B; }

.requests .pagination_wrapper {
  padding: 30px 0 0 0; }

.requests .add_btn img {
  margin: 0 10px 0 0; }

.requests .requests_table {
  padding: 0; }

.requests .transactions_columns .table_header .row_item {
  display: flex;
  align-items: center; }

.requests .transactions_columns .table_row {
  align-items: flex-start;
  height: auto;
  flex-flow: column;
  text-decoration: none; }
  .requests .transactions_columns .table_row .row_orders {
    cursor: pointer;
    width: 100%;
    display: flex;
    height: 60px;
    align-items: center; }
    .requests .transactions_columns .table_row .row_orders > :first-child {
      width: 30%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(2) {
      width: 15%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(3) {
      width: 15%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(4) {
      width: 17%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(5) {
      width: 17%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(6) {
      width: 17%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(7) {
      width: 10%; }
    .requests .transactions_columns .table_row .row_orders > :nth-child(8) {
      width: 16%; }

.requests .info_items {
  padding: 0px 0 20px;
  display: flex;
  width: 100%;
  flex-flow: column; }
  .requests .info_items .descriptions {
    padding-bottom: 10px;
    color: #7F8FA4;
    font-size: 12px; }
  .requests .info_items .comment {
    word-break: break-all;
    color: #334150;
    font-size: 16px;
    padding-bottom: 0; }

.requests .block_status {
  display: flex;
  align-items: center; }

.requests .status_orders {
  height: 6px;
  width: 6px;
  display: flex;
  border-radius: 50%;
  margin-right: 5px; }

.requests .blue {
  background: #249AF3; }

.requests .green {
  background: #16D761; }

.requests .red {
  background: #D0021B; }

.requests .pagination_wrapper {
  padding: 30px 0 0 0; }

.panel_page {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .panel_page .ml20px {
    margin-left: 20px; }
  .panel_page .search_transactions_panel {
    width: 471px; }
    .panel_page .search_transactions_panel .search_product_wrapper {
      width: 100%; }
    .panel_page .search_transactions_panel .search_input_icon {
      display: flex;
      align-items: center;
      padding-right: 12px;
      cursor: pointer; }

.select_wrapper {
  width: 201px;
  height: 41px; }
  .select_wrapper .select__menu .select__menu-list {
    padding: 0 8px !important; }
    .select_wrapper .select__menu .select__menu-list > div {
      color: #7f8fa4;
      box-sizing: border-box;
      border-bottom: 1px solid #c2d1d9;
      padding: 15px 0px !important;
      background: #fff !important;
      cursor: pointer; }
      .select_wrapper .select__menu .select__menu-list > div:hover {
        background: #fff !important; }
    .select_wrapper .select__menu .select__menu-list > div:last-of-type {
      border-bottom: none; }

.select__value-container:hover {
  cursor: text; }

.select__indicators:hover {
  cursor: pointer; }

.transaction_history {
  margin-top: -20px; }
  .transaction_history .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .transaction_history .transactions_columns .table_body .table_row > :nth-child(2) {
    font-weight: 600; }
  .transaction_history .transactions_columns .table_body .table_row > :last-child {
    font-weight: 600; }
  .transaction_history .transactions_columns .table_row > :first-child {
    width: 28%; }
  .transaction_history .transactions_columns .table_row > :nth-child(2) {
    width: 28%; }
    .transaction_history .transactions_columns .table_row > :nth-child(2) .ru_value {
      font-weight: 500; }
  .transaction_history .transactions_columns .table_row > :nth-child(3) {
    width: 28%; }
  .transaction_history .transactions_columns .table_row > :last-child {
    width: 16%; }
    .transaction_history .transactions_columns .table_row > :last-child .ru_value {
      font-weight: 500; }
  .transaction_history .pagination_wrapper {
    padding: 30px 0 0 0; }

.up-btn {
  position: absolute;
  bottom: 30px;
  right: 70px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  color: #0253b3;
  font-family: "Akrobat SemiBold";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: baseline; }
  .up-btn img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    padding: 0 5px; }

.purchases {
  padding: 40px 50px 40px;
  background: #fff;
  margin-bottom: 70px; }
  .purchases .my_orders .transactions_columns {
    padding-bottom: 40px; }
    .purchases .my_orders .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center; }
    .purchases .my_orders .transactions_columns .table_row > :first-child {
      width: 25%; }
    .purchases .my_orders .transactions_columns .table_row > :nth-child(2) {
      width: 25%;
      display: flex;
      flex-direction: column; }
    .purchases .my_orders .transactions_columns .table_row > :nth-child(3) {
      width: 25%; }
    .purchases .my_orders .transactions_columns .table_row > :nth-child(4) {
      width: 20%;
      display: flex; }
    .purchases .my_orders .transactions_columns .table_row > :last-child {
      width: 5%;
      justify-content: flex-end;
      display: flex; }
      .purchases .my_orders .transactions_columns .table_row > :last-child button {
        cursor: pointer;
        background: transparent; }

.purchases_page {
  position: relative; }

.purchases_tabs .tabs_block_purchases {
  margin-bottom: 42px; }
  .purchases_tabs .tabs_block_purchases button {
    color: #7f8fa4;
    font-size: 16px;
    position: relative;
    margin-right: 30px;
    background: transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .purchases_tabs .tabs_block_purchases button:hover {
      color: #334150; }
  .purchases_tabs .tabs_block_purchases .active {
    color: #334150; }
    .purchases_tabs .tabs_block_purchases .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      height: 2px;
      width: 100%;
      background: #0253b3; }

.purchases_tabs .table_container .table_header .table_header {
  display: flex; }

.purchases_tabs .table_header .row_item {
  color: #7f8fa4;
  font-size: 12px; }

.purchases_tabs .table_body .row_item {
  color: #334150;
  font-size: 16px; }

.purchases_tabs .table_row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.requests_add {
  padding: 40px 50px 30px;
  background: #fff;
  margin-bottom: 70px; }

.requests_add_search {
  padding-bottom: 20px;
  display: flex;
  flex-flow: column;
  position: relative; }
  .requests_add_search span {
    color: #7f8fa4;
    font-size: 12px;
    padding-bottom: 4px; }
  .requests_add_search input {
    color: #334150;
    box-sizing: border-box;
    border: 1px solid #c2d1d9;
    border-radius: 3px;
    padding: 0 12px;
    font-size: 14px;
    height: 41px;
    width: 571px;
    font-family: "Akrobat Regular", sans-serif; }
    .requests_add_search input::-webkit-input-placeholder {
      color: #7f8fa4; }
    .requests_add_search input::-moz-placeholder {
      color: #7f8fa4; }
    .requests_add_search input::-ms-input-placeholder {
      color: #7f8fa4; }
    .requests_add_search input::placeholder {
      color: #7f8fa4; }

.amount_requests {
  margin-bottom: 20px;
  display: flex; }
  .amount_requests > div:first-of-type {
    display: flex;
    flex-flow: column;
    margin-right: 50px; }
  .amount_requests .descriptions_amount {
    padding-bottom: 4px;
    color: #7f8fa4;
    font-size: 12px; }
  .amount_requests .amount {
    border: 1px solid #c2d1d9;
    border-radius: 3px;
    height: 41px;
    width: 93px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px; }
    .amount_requests .amount span {
      width: 23px;
      text-align: center; }
    .amount_requests .amount button {
      cursor: pointer;
      background: transparent;
      height: 100%;
      display: flex;
      width: 35px;
      position: relative;
      text-align: center;
      justify-content: center; }
      .amount_requests .amount button svg {
        font-size: 20px;
        fill: #334150; }
  .amount_requests .price_all {
    color: #334150;
    font-size: 23px;
    display: flex;
    flex-flow: column; }
    .amount_requests .price_all .price {
      color: #334150;
      font-size: 23px;
      line-height: 41px; }
  .amount_requests .info_amount {
    color: #7f8fa4;
    font-size: 12px; }
    .amount_requests .info_amount img {
      margin-right: 5px; }
  .amount_requests .block_amount {
    display: flex;
    align-items: center; }
    .amount_requests .block_amount > div > button {
      display: flex;
      align-items: center; }

.block_textarea_amount span {
  padding-bottom: 4px;
  color: #7f8fa4;
  font-size: 12px;
  display: block; }

.block_textarea_amount textarea {
  display: flex;
  resize: none;
  padding: 12px;
  box-sizing: border-box;
  min-height: 59px;
  width: 571px;
  color: #334150;
  border: 1px solid #c2d1d9;
  border-radius: 3px; }
  .block_textarea_amount textarea::-webkit-input-placeholder {
    color: #7f8fa4; }
  .block_textarea_amount textarea::-moz-placeholder {
    color: #7f8fa4; }
  .block_textarea_amount textarea::-ms-input-placeholder {
    color: #7f8fa4; }
  .block_textarea_amount textarea::placeholder {
    color: #7f8fa4; }

.attach_file_block {
  padding-top: 20px; }
  .attach_file_block .attach_btn {
    padding-bottom: 9px;
    color: #0253b3;
    font-size: 12px;
    cursor: pointer;
    background: transparent; }
    .attach_file_block .attach_btn img {
      margin-right: 5px; }
  .attach_file_block .document_info {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px; }
    .attach_file_block .document_info .doc_icon {
      margin-right: 5px;
      height: 15px;
      width: 11px; }
    .attach_file_block .document_info span {
      color: #7f8fa4;
      font-size: 12px; }
    .attach_file_block .document_info .btn_delete_doc {
      margin-left: 20px;
      background: transparent;
      cursor: pointer; }

.search_product_wrapper {
  display: block; }
  .search_product_wrapper input {
    border: 1px solid transparent;
    border-radius: 3px;
    height: 41px;
    width: 100%;
    line-height: 41px;
    padding: 0 12px;
    color: #01397c;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 14px;
    font-weight: 300; }
    .search_product_wrapper input::-webkit-input-placeholder {
      color: #7f8fa4; }
    .search_product_wrapper input::-moz-placeholder {
      color: #7f8fa4; }
    .search_product_wrapper input::-ms-input-placeholder {
      color: #7f8fa4; }
    .search_product_wrapper input::placeholder {
      color: #7f8fa4; }

body #products_popper {
  z-index: 1500; }
  body #products_popper .popper_wrapper {
    border: 1px solid #c2d1d9;
    max-height: 400px;
    overflow: auto;
    width: 740px; }

.search_product_item {
  padding: 15px 0;
  border-bottom: 1px solid #c2d1d9;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer; }

.requests_add .search_products_list {
  width: 571px;
  border: 1px solid #c2d1d9;
  border-radius: 3px;
  padding: 0 15px;
  position: absolute;
  left: 0;
  top: 66px;
  background: #fff;
  z-index: 11; }
  .requests_add .search_products_list > :first-child {
    padding-top: 10px; }
  .requests_add .search_products_list > :last-child {
    border-bottom: none;
    padding-bottom: 10px; }

.requests_add .search_product_item {
  padding: 15px 0;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer; }
  .requests_add .search_product_item:hover {
    background: #fff; }

.requests_add .search_product_item_head {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .requests_add .search_product_item_head span {
    color: #01397c;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 0; }

.requests_add .search_product_item_info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  .requests_add .search_product_item_info .block_value_currency {
    display: flex;
    font-weight: 600;
    font-family: "Akrobat Regular", sans-serif;
    justify-content: flex-end; }
    .requests_add .search_product_item_info .block_value_currency .ru_value {
      font-weight: 400;
      padding-left: 3px; }
  .requests_add .search_product_item_info > div {
    width: 20%; }
    .requests_add .search_product_item_info > div .block_label {
      color: #7f8fa4;
      font-size: 12px;
      font-weight: 300;
      line-height: 15px; }
    .requests_add .search_product_item_info > div .block_value {
      font-weight: 400;
      color: #334150;
      font-size: 14px;
      line-height: 17px; }
  .requests_add .search_product_item_info > :first-of-type {
    width: 25%; }
  .requests_add .search_product_item_info > :nth-child(2) {
    width: 20%; }
  .requests_add .search_product_item_info > :nth-child(4) {
    width: 15%; }
  .requests_add .search_product_item_info > :last-child {
    width: 15%;
    text-align: right;
    line-height: 27px;
    font-size: 23px; }

.search_product_item_head {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .search_product_item_head h4 {
    color: #01397c;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .search_product_item_head span {
    color: #7f8fa4;
    font-size: 12px;
    line-height: 15px; }
  .search_product_item_head .highlight {
    background: #a4e7ff; }

.search_product_item_info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  .search_product_item_info > div {
    width: 20%; }
  .search_product_item_info > :last-child {
    text-align: right;
    font-family: Roboto, sans-serif;
    line-height: 27px;
    font-size: 23px; }
  .search_product_item_info .block_value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.select_error_tooltip {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .select_error_tooltip svg {
    fill: #ff0000; }

.item_page_block {
  padding: 40px 50px;
  margin-bottom: 60px;
  background: #fff; }

.file_add {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer; }
  .file_add span {
    padding-left: 7px;
    color: #0253B3;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px; }

.file_add:last-of-type {
  padding: 0; }

.item_page > .item_page_block:last-of-type {
  margin-bottom: 60px; }

.item_page > .item_page_block:first-of-type {
  margin-bottom: 30px; }

.refund_requests_page_block .transactions_table .transactions_columns {
  width: 100%; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_body .table_row > :nth-child(3) {
    font-weight: 600; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_row > :first-child {
    width: 25%; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_row > :nth-child(2) {
    width: 25%; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_row > :nth-child(3) {
    width: 25%; }
  .refund_requests_page_block .transactions_table .transactions_columns .table_row > :last-child {
    width: 25%; }

.wrapper_btn_items {
  display: flex; }
  .wrapper_btn_items .default_button_wrapper {
    margin-right: 15px; }

.descriptions_dialog_component {
  color: #334150;
  font-size: 16px;
  line-height: 20px; }

.block_status_info {
  display: flex;
  align-items: center; }
  .block_status_info .text {
    color: #334150;
    font-size: 16px;
    padding-left: 5px; }
  .block_status_info .status {
    border-radius: 50%;
    height: 6px;
    width: 6px; }

.block_status_info > span.text {
  padding-bottom: 0; }

textarea {
  font-family: "Akrobat Regular", sans-serif;
  resize: none;
  box-sizing: border-box;
  min-height: 100px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #c2d1d9;
  border-radius: 3px;
  padding: 12px 11px; }
  textarea::-webkit-input-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300; }
  textarea::-moz-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300; }
  textarea::-ms-input-placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300; }
  textarea::placeholder {
    font-family: "Akrobat Regular", sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 300; }
  textarea:hover {
    border-color: var(--color-constants-blue-light); }
  textarea:focus {
    border-color: var(--color-constants-blue-light);
    box-shadow: 0 0 0 3px var(--color-constants-blue-lightest); }
  textarea:focus-visible {
    outline: 0; }

.item_page .title_items {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 20px; }

.item_page .descriptions_items {
  padding-bottom: 7px;
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px; }

.item_page .text {
  padding-bottom: 23px;
  color: #334150;
  font-size: 16px;
  line-height: 18px; }

.item_page .title_items_mini {
  display: flex;
  color: #334150;
  font-size: 23px;
  line-height: 29px;
  padding-bottom: 20px; }

.item_page .select_wrapper {
  width: 301px; }

.item_page .status_wrapper .title_items_mini span {
  margin-right: 5px; }

.item_page .btn_wrapper_product {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  display: flex; }
  .item_page .btn_wrapper_product .default_button_wrapper {
    margin-right: 15px; }
  .item_page .btn_wrapper_product .file_error {
    cursor: pointer;
    color: red; }

.line_wrapper {
  background: #C2D1D9;
  width: 100%;
  height: 1px;
  margin: 30px 0; }

.half_block_wrapper_items {
  display: flex;
  align-items: center; }
  .half_block_wrapper_items > div:first-of-type {
    width: 30%; }
  .half_block_wrapper_items > div:last-of-type {
    width: 70%; }

.indent_no {
  margin: 0 !important;
  padding: 0 !important; }

.info-social-wrapper {
  padding-left: 20px; }
  .info-social-wrapper img {
    padding-right: 8px; }

.descriptions_dialog_component {
  margin-top: 30px;
  color: #334150;
  font-size: 16px;
  line-height: 20px; }

.item_page .title_items {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 20px; }

.item_page .descriptions_items {
  padding-bottom: 7px;
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px; }

.item_page .text {
  padding-bottom: 23px;
  color: #334150;
  font-size: 16px;
  line-height: 18px; }

.item_page .title_items_mini {
  display: flex;
  color: #334150;
  font-size: 23px;
  line-height: 29px;
  padding-bottom: 20px; }

.item_page .select_wrapper {
  width: 301px; }

.item_page .status_wrapper .title_items_mini span {
  margin-right: 5px; }

.item_page .btn_wrapper_product {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  display: flex; }
  .item_page .btn_wrapper_product .default_button_wrapper {
    margin-right: 15px; }
  .item_page .btn_wrapper_product .file_error {
    cursor: pointer;
    color: red; }

.comment-component .comment-component__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px; }

.comment-component .comment-component__body {
  margin-top: 10px; }

.comment-component .comment-component-header__title {
  color: var(--color-constants-blue-dark); }

.download-file_error {
  cursor: pointer;
  margin-left: 15px; }
  .download-file_error svg {
    fill: #ff0000; }

.search_wrapper {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #C2D1D9;
  height: 40px;
  width: 100%;
  display: flex; }
  .search_wrapper.search_wrapper_small {
    width: 275px;
    justify-content: space-between; }
    .search_wrapper.search_wrapper_small > div:first-child {
      width: calc(100% - 34px); }
    .search_wrapper.search_wrapper_small .search_input_icon {
      display: flex;
      align-items: center;
      width: 34px; }
  .search_wrapper input {
    width: calc(100% - 34px);
    height: 38px;
    line-height: 38px;
    padding: 0 12px;
    font-size: 14px;
    font-family: "Akrobat Regular", sans-serif; }
    .search_wrapper input::-webkit-input-placeholder {
      color: #7F8FA4; }
    .search_wrapper input::-moz-placeholder {
      color: #7F8FA4; }
    .search_wrapper input::-ms-input-placeholder {
      color: #7F8FA4; }
    .search_wrapper input::placeholder {
      color: #7F8FA4; }
  .search_wrapper button {
    padding: 5px 7px;
    border-radius: 0; }
    .search_wrapper button svg {
      font-size: 20px;
      fill: #C2D1D9; }

.download-file {
  display: flex;
  align-items: center;
  margin-bottom: 41px; }
  .download-file .download {
    color: #c2d1d9;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    box-sizing: border-box;
    border: 1px solid #c2d1d9;
    border-radius: 3px 0 0 3px;
    height: 41px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    width: 202px;
    cursor: default; }
    .download-file .download.download_error {
      border: 1px solid #ff0000; }
    .download-file .download span {
      color: #334150;
      max-height: 35px;
      overflow: hidden; }
    .download-file .download input {
      display: none; }
  .download-file .default_button_wrapper .default_button {
    border-radius: 0 3px 3px 0;
    height: 41px;
    min-width: 98px; }

.own-flex-wrapper {
  display: flex;
  align-items: flex-start; }

.copy-div {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 30px;
  font-family: "Akrobat Regular";
  color: #0253b3;
  margin-left: 50px;
  cursor: pointer; }
  .copy-div > img {
    margin-right: 10px; }
  .copy-div.green-text {
    color: #16d761; }

.price-check {
  margin-top: 30px; }

.accounting-disabled {
  pointer-events: none;
  opacity: 0.4; }

.price-desc {
  color: #7f8fa4;
  font-size: 12px;
  font-family: "Akrobat Light";
  margin-left: 25px;
  width: 278px; }

.price-form .price-field {
  display: flex;
  align-items: center; }
  .price-form .price-field .custom_input_wrapper {
    margin-right: 50px; }

.price-form .auto_update_wrapper {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #c2d1d9;
  display: flex;
  flex-flow: column;
  width: 100%; }
  .price-form .auto_update_wrapper .title {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 10px; }
  .price-form .auto_update_wrapper .auto_update_option {
    width: 650px;
    margin-top: 10px;
    padding: 12px;
    display: flex;
    flex-flow: column;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 24px 0 rgba(204, 204, 204, 0.25); }
    .price-form .auto_update_wrapper .auto_update_option .clickable_area {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .price-form .auto_update_wrapper .auto_update_option .clickable_area > img {
        height: 32px;
        width: 32px;
        margin-right: 12px; }
      .price-form .auto_update_wrapper .auto_update_option .clickable_area .option_name > div {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px; }
        .price-form .auto_update_wrapper .auto_update_option .clickable_area .option_name > div.active {
          color: #0253b3; }
      .price-form .auto_update_wrapper .auto_update_option .clickable_area .option_name > p {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        color: #7f8fa4; }
        .price-form .auto_update_wrapper .auto_update_option .clickable_area .option_name > p > span {
          font-family: "Akrobat SemiBold", sans-serif; }
    .price-form .auto_update_wrapper .auto_update_option .fields_email {
      padding: 30px 44px 18px; }
      .price-form .auto_update_wrapper .auto_update_option .fields_email .custom_input_wrapper {
        width: 259px !important; }
      .price-form .auto_update_wrapper .auto_update_option .fields_email .first_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px; }
      .price-form .auto_update_wrapper .auto_update_option .fields_email .second_row {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .price-form .auto_update_wrapper .auto_update_option .fields_email .second_row > div {
          width: 259px;
          color: #7f8fa4;
          font-size: 12px;
          line-height: 15px; }
          .price-form .auto_update_wrapper .auto_update_option .fields_email .second_row > div > img {
            height: 16px;
            width: 16px;
            margin-right: 8px; }
    .price-form .auto_update_wrapper .auto_update_option .fields_link {
      padding: 30px 44px 18px; }
      .price-form .auto_update_wrapper .auto_update_option .fields_link .link_field .custom_input_wrapper {
        max-width: 100% !important; }
      .price-form .auto_update_wrapper .auto_update_option .fields_link .with_login_and_password {
        margin: 20px 0 32px;
        cursor: pointer;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        font-size: 14px;
        letter-spacing: 0; }
        .price-form .auto_update_wrapper .auto_update_option .fields_link .with_login_and_password > img {
          width: 18px;
          height: 18px;
          margin-right: 8px; }
      .price-form .auto_update_wrapper .auto_update_option .fields_link .login_and_password_fields {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0.4; }
        .price-form .auto_update_wrapper .auto_update_option .fields_link .login_and_password_fields.active {
          opacity: 1; }
        .price-form .auto_update_wrapper .auto_update_option .fields_link .login_and_password_fields .custom_input_wrapper {
          width: 259px !important; }

.download-file_error {
  cursor: pointer;
  margin-left: 15px; }
  .download-file_error svg {
    fill: #ff0000; }

.price-settings .title {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 30px; }

.price-settings .columns-table {
  display: inline-block; }

.columns-block {
  display: flex; }
  .columns-block .columns-title {
    display: flex;
    flex-flow: column;
    margin-right: 100px; }
    .columns-block .columns-title button {
      text-align: left;
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      background: transparent;
      width: 100%;
      margin-bottom: 15px; }
    .columns-block .columns-title button:last-of-type {
      margin-bottom: 0; }
    .columns-block .columns-title .active-btn {
      color: #334150; }
    .columns-block .columns-title .success-btn {
      color: #16D761; }

.price-table {
  width: 100%;
  overflow: hidden; }
  .price-table .descriptions {
    padding-bottom: 10px;
    color: #334150;
    font-size: 12px;
    line-height: 15px; }

.scroll_block {
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 20px; }
  .scroll_block::-webkit-scrollbar {
    height: 6px;
    top: 10px; }
  .scroll_block::-webkit-scrollbar-track {
    margin-top: 20px;
    border-radius: 2px;
    background: #EDF2F8; }
  .scroll_block::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #0253B3; }
  .scroll_block::-webkit-scrollbar-thumb:window-inactive {
    background: #0253B3; }

.columns-table {
  border: 1px solid #C2D1D9; }
  .columns-table > :first-child {
    border-top: none; }
  .columns-table .table_row > :first-child {
    width: 30px;
    min-width: 30px; }
  .columns-table .table_row div {
    width: 110px;
    min-width: 110px; }
  .columns-table .table_row .table_cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .columns-table .table_row {
    height: 30px;
    border-top: 1px solid #C2D1D9; }
    .columns-table .table_row > :first-child {
      color: #7F8FA4;
      font-size: 14px;
      line-height: 30px;
      text-align: center; }
    .columns-table .table_row div, .columns-table .table_row button {
      border-right: 1px solid #C2D1D9;
      height: 100%; }
    .columns-table .table_row > :last-child {
      border-right: none; }

.table_head_cell {
  color: #7F8FA4;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.table_cell {
  color: #334150;
  font-size: 12px;
  line-height: 30px;
  padding: 0 5px; }

.settings-form {
  margin-top: 80px; }
  .settings-form .price-field {
    display: flex;
    align-items: center; }
    .settings-form .price-field .drop-down {
      margin-right: 50px; }
      .settings-form .price-field .drop-down .select_wrapper {
        margin-left: 0;
        position: relative; }
        .settings-form .price-field .drop-down .select_wrapper .span-label {
          position: absolute;
          top: -18px;
          left: 0;
          font-size: 12px;
          color: #7f8fa4; }
    .settings-form .price-field .custom_input_wrapper {
      max-width: 53px; }
      .settings-form .price-field .custom_input_wrapper .custom_input {
        padding: 0; }
        .settings-form .price-field .custom_input_wrapper .custom_input input {
          padding-right: 0;
          color: #334150; }

.dialog_wrapper.dialog_wrapper_long {
  overflow: hidden; }

.undefined-producers-wrapper {
  min-height: 25vh;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: auto; }
  .undefined-producers-wrapper .undefined-producers-item {
    display: block;
    margin-bottom: 10px; }
    .undefined-producers-wrapper .undefined-producers-item > input {
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      height: 41px;
      width: 300px;
      line-height: 41px;
      padding: 0 12px;
      color: #01397C;
      font-family: "Akrobat Regular", sans-serif;
      font-size: 14px;
      font-weight: 300;
      margin-right: 19px; }
      .undefined-producers-wrapper .undefined-producers-item > input::-webkit-input-placeholder {
        color: #7F8FA4; }
      .undefined-producers-wrapper .undefined-producers-item > input::-moz-placeholder {
        color: #7F8FA4; }
      .undefined-producers-wrapper .undefined-producers-item > input::-ms-input-placeholder {
        color: #7F8FA4; }
      .undefined-producers-wrapper .undefined-producers-item > input::placeholder {
        color: #7F8FA4; }
      .undefined-producers-wrapper .undefined-producers-item > input:disabled {
        background-color: #FFFFFF; }
    .undefined-producers-wrapper .undefined-producers-item img {
      width: 11px;
      height: 8px;
      margin-right: 19px; }
    .undefined-producers-wrapper .undefined-producers-item div.select_wrapper {
      margin-left: 0; }
  .undefined-producers-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(2, 83, 179, 0.05); }
  .undefined-producers-wrapper::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5; }
  .undefined-producers-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #0253b3; }

.markup {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #C2D1D9; }
  .markup .markup-stepped .custom_input_wrapper .custom_input input {
    color: #334150;
    padding-right: 0; }

.col_btn {
  height: 30px;
  min-width: 110px;
  text-align: center;
  cursor: pointer;
  background: #EDF2F8;
  color: #7F8FA4;
  font-size: 14px;
  line-height: 17px;
  border-left: none;
  border-bottom: none; }
  .col_btn:disabled {
    cursor: default;
    background-color: #EDF2F8 !important; }
    .col_btn:disabled:hover {
      background: #EDF2F8; }
  .col_btn:hover {
    background: rgba(2, 83, 179, 0.1); }
    .col_btn:hover ~ div {
      background: rgba(2, 83, 179, 0.1); }

div .custom_radio {
  margin-left: 0;
  margin-bottom: 20px; }
  div .custom_radio > span {
    padding: 0;
    margin-right: 5px;
    color: #334150; }

.markup-title {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 30px; }

.markup-fixed {
  padding: 5px 0 25px 22px; }
  .markup-fixed .custom_input_wrapper {
    max-width: 93px; }

.markup-stepped {
  padding: 5px 0 0 22px; }
  .markup-stepped .custom_input_wrapper {
    max-width: 93px;
    margin-right: 20px; }
  .markup-stepped > div {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .markup-stepped > div .custom_input_wrapper:first-of-type .custom_input {
      padding-right: 0; }
      .markup-stepped > div .custom_input_wrapper:first-of-type .custom_input input {
        min-width: 39px;
        text-align: right; }
  .markup-stepped .other-markup .custom_input_wrapper:first-of-type .custom_input input {
    text-align: left; }
  .markup-stepped .markup-btn {
    margin-left: 30px;
    background: transparent;
    cursor: pointer;
    color: #0253B3;
    font-size: 16px;
    text-align: right; }
    .markup-stepped .markup-btn.markup-btn-remove {
      color: #FF0000; }
  .markup-stepped .other-markup {
    margin: 0;
    padding-top: 20px; }

.price-add {
  margin-bottom: 60px;
  background: #fff;
  padding: 40px 50px; }

.tab-button {
  margin-bottom: 40px; }
  .tab-button button {
    margin-right: 50px;
    font-size: 32px;
    line-height: 40px;
    color: #C2D1D9;
    cursor: default;
    background: transparent;
    font-family: "Akrobat Regular", sans-serif; }
  .tab-button .active {
    color: #334150; }

.btn-wrapper {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #C2D1D9;
  display: flex;
  align-items: center;
  width: 100%; }
  .btn-wrapper .default_button_wrapper {
    margin-right: 14px; }

.price-list .dashboard-section_content {
  display: block;
  padding-top: 0; }
  .price-list .dashboard-section_content:after {
    content: "";
    display: table;
    clear: both; }
  .price-list .dashboard-section_content .review-item {
    height: 250px; }
  .price-list .dashboard-section_content .price-item {
    height: 265px; }
  .price-list .dashboard-section_content .price-item,
  .price-list .dashboard-section_content .review-item {
    position: relative;
    float: left;
    margin-right: 2%;
    width: 23.5%; }
    .price-list .dashboard-section_content .price-item:nth-child(4n),
    .price-list .dashboard-section_content .review-item:nth-child(4n) {
      margin-right: 0; }
    .price-list .dashboard-section_content .price-item .block_value_extra,
    .price-list .dashboard-section_content .review-item .block_value_extra {
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis; }

.price_inner-content {
  padding: 50px 50px 40px 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .price_inner-content .Toastify__toast-body > div:last-child {
    font-size: 15px; }
  .price_inner-content .search_wrapper_price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; }
    @media (max-width: 1120px) {
      .price_inner-content .search_wrapper_price {
        flex-direction: column; } }
    .price_inner-content .search_wrapper_price .search_wrapper {
      width: 50%; }
      @media (max-width: 1120px) {
        .price_inner-content .search_wrapper_price .search_wrapper {
          width: 100%; } }
      .price_inner-content .search_wrapper_price .search_wrapper .search_input_icon {
        display: flex;
        align-items: center;
        width: 34px; }
  @media (max-width: 1120px) {
    .price_inner-content .price_inner__buttons {
      margin-top: 20px; } }
  .price_inner-content .price_inner__btn-download {
    margin-right: 40px; }
  .price_inner-content .table {
    width: 100%; }
    .price_inner-content .table .table_head {
      box-sizing: border-box;
      background-color: #edf2f8;
      height: 30px; }
    .price_inner-content .table .table_body > div:first-child {
      position: relative; }
    .price_inner-content .table .table_body .ReactVirtualized__Grid.ReactVirtualized__List {
      overflow: hidden auto !important; }
      .price_inner-content .table .table_body .ReactVirtualized__Grid.ReactVirtualized__List::-webkit-scrollbar {
        width: 10px;
        margin-left: 10px; }
      .price_inner-content .table .table_body .ReactVirtualized__Grid.ReactVirtualized__List::-webkit-scrollbar-track {
        background-color: #edf2f8;
        border-radius: 2.5px; }
      .price_inner-content .table .table_body .ReactVirtualized__Grid.ReactVirtualized__List::-webkit-scrollbar-thumb {
        background-color: #01397c;
        border-radius: 2.5px; }
    .price_inner-content .table .table_body .no_item {
      margin-top: 12px; }
    .price_inner-content .table .table_body .ReactVirtualized__Grid__innerScrollContainer {
      width: inherit !important; }
    .price_inner-content .table .col {
      border-right: 1px solid #c2d1d9;
      vertical-align: middle;
      padding: 0 8px;
      display: flex;
      align-items: center;
      font-size: 12px;
      position: relative; }
      .price_inner-content .table .col .search_highlight {
        display: none; }
        .price_inner-content .table .col .search_highlight.visible {
          display: block;
          width: 100%;
          font-size: 12px;
          line-height: 15px;
          min-height: 15px;
          color: #334150;
          white-space: nowrap;
          overflow: hidden;
          letter-spacing: 0.7px;
          text-overflow: ellipsis; }
          .price_inner-content .table .col .search_highlight.visible span {
            font-weight: 900;
            background-color: #fdd0b3;
            padding: 1px 0px;
            display: inline-block;
            margin: 0 1px; }
      .price_inner-content .table .col .field {
        display: none; }
        .price_inner-content .table .col .field.visible {
          display: block;
          background-color: transparent;
          color: #334150;
          font-size: 12px;
          line-height: 15px;
          width: 100%;
          height: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          letter-spacing: 0.7px;
          font-family: 'Akrobat Regular';
          padding: 0 2px; }
          .price_inner-content .table .col .field.visible:focus + p {
            display: block; }
      .price_inner-content .table .col .add_field {
        display: block;
        background-color: transparent;
        color: #334150;
        font-size: 13px;
        line-height: 15px;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Akrobat Regular';
        padding: 0 2px; }
        .price_inner-content .table .col .add_field:focus + p {
          display: block; }
      .price_inner-content .table .col .select_component {
        display: block;
        position: absolute;
        bottom: 8px;
        left: -5px;
        z-index: 2;
        background-color: transparent;
        font-size: 12px;
        width: calc(100% + 10px); }
        .price_inner-content .table .col .select_component .select__control--is-focused .indicator_active svg {
          -webkit-transform: rotate(0);
                  transform: rotate(0); }
        .price_inner-content .table .col .select_component .select__indicator-separator {
          display: none; }
        .price_inner-content .table .col .select_component .select__control {
          z-index: 12;
          position: relative;
          top: 43px;
          margin: 0 7px;
          border: 1px solid #c2d1d9; }
        .price_inner-content .table .col .select_component .select__menu {
          z-index: 10;
          padding-right: 5px;
          padding-bottom: 8px; }
          .price_inner-content .table .col .select_component .select__menu-list {
            margin-top: 50px;
            height: 244px; }
            .price_inner-content .table .col .select_component .select__menu-list::-webkit-scrollbar {
              width: 3px;
              margin-left: 10px; }
            .price_inner-content .table .col .select_component .select__menu-list::-webkit-scrollbar-track {
              background-color: #edf2f8;
              border-radius: 2.5px; }
            .price_inner-content .table .col .select_component .select__menu-list::-webkit-scrollbar-thumb {
              background-color: #01397c;
              border-radius: 2.5px; }
      .price_inner-content .table .col .producer_name {
        display: block;
        position: unset;
        bottom: 0;
        left: auto;
        width: 100%;
        border: none;
        background-color: inherit;
        padding: 0;
        font-size: 12px; }
      .price_inner-content .table .col-btn {
        display: block;
        position: absolute;
        top: 1px;
        left: 100%;
        padding-left: 7px;
        opacity: 0;
        cursor: pointer; }
        .price_inner-content .table .col-btn button {
          background-color: #fff;
          cursor: inherit; }
      .price_inner-content .table .col:nth-child(1) {
        width: 10%; }
      .price_inner-content .table .col:nth-child(2) {
        width: 15%; }
      .price_inner-content .table .col:nth-child(3) {
        width: 37%; }
      .price_inner-content .table .col:nth-child(4) {
        width: 4%; }
      .price_inner-content .table .col:nth-child(5) {
        width: 10%; }
      .price_inner-content .table .col:nth-child(6) {
        width: 6%; }
      .price_inner-content .table .col:nth-child(7) {
        width: 6%; }
      .price_inner-content .table .col:nth-child(8) {
        width: 6%; }
      .price_inner-content .table .col:nth-child(9) {
        width: 6%; }
    .price_inner-content .table .row {
      border-bottom: 1px solid #c2d1d9;
      height: 30px;
      position: relative; }
      .price_inner-content .table .row:first-child {
        border-top: none; }
      .price_inner-content .table .row.new {
        border-bottom: 1px solid #c2d1d9; }
      .price_inner-content .table .row:hover {
        background-color: #edf2f8; }
        .price_inner-content .table .row:hover .col-btn {
          opacity: 1; }
    .price_inner-content .table .table_head,
    .price_inner-content .table .row {
      display: flex; }
    .price_inner-content .table .col:nth-child(1) {
      border-left: 1px solid #c2d1d9; }
    .price_inner-content .table .col:nth-child(9) {
      border-right: 1px solid #c2d1d9; }
    .price_inner-content .table .col.error {
      border: 1px solid red; }
  .price_inner-content .table_head {
    border-top: 1px solid #c2d1d9;
    border-bottom: 1px solid #c2d1d9; }
  .price_inner-content .small-table {
    border-bottom: none; }
    .price_inner-content .small-table .row:last-child {
      border-bottom: 1px solid #c2d1d9; }
  .price_inner-content .up-btn {
    position: absolute;
    bottom: -24%;
    right: 52px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    color: #0253b3;
    font-family: 'Akrobat SemiBold';
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: baseline; }
    .price_inner-content .up-btn img {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      padding: 0 5px; }

.auth_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 150px;
  background: #01397c;
  height: 80px; }
  .auth_header p {
    color: #ffffff;
    font-size: 14px;
    line-height: 13px;
    margin-right: 29px; }
  .auth_header .default_button_transparent {
    min-width: 147px;
    height: 41px;
    font-size: 14px;
    padding: 0; }

.auth_container {
  width: 100%;
  min-height: 600px;
  height: calc(100vh - 80px);
  background-color: #01397c;
  padding: 20px 150px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .auth_container form {
    width: 300px;
    position: relative; }
  .auth_container .custom_input_wrapper {
    margin-bottom: 20px; }
  .auth_container .default_button_wrapper {
    margin-top: 10px; }

.auth_bg {
  background: url(/static/media/auth_bg.3630f1b8.png) no-repeat 50% 50%;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 50px 70px 50px 100px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px; }
  .auth_bg p {
    width: 370px; }

.auth_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }

.auth-box {
  width: 100%;
  height: 100%;
  display: flex; }
  .auth-box > :first-child {
    width: 50%; }
  .auth-box > :last-child {
    width: 50%;
    background-color: #ffffff; }

.auth-block_head {
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 50px;
  position: relative; }
  .auth-block_head:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50px;
    height: 1px;
    background-color: #0253b3; }

.auth_logo {
  border-radius: 20px;
  margin-bottom: 40px;
  margin-top: -100px; }

.logo_center {
  margin-top: 120px; }

.auth_default_text {
  color: #a1aeb7;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 30px 0; }

.auth_link {
  color: #0253b3;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: 40px;
  position: absolute;
  bottom: 50px; }
  .auth_link:hover {
    text-decoration: underline; }

.auth_btn_wrapper {
  width: 300px;
  margin-top: 41px; }

.form_start {
  margin-top: -10vh; }

.auth_error {
  color: #ff0000;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: -24px;
  width: 100%; }

.auth-block_wrapper {
  width: 300px; }
  .auth-block_wrapper p {
    color: #01397c;
    font-size: 16px;
    line-height: 24px; }
  .auth-block_wrapper .default_button_wrapper {
    margin-top: 30px; }

