.requests_add {
    padding: 40px 50px 30px;
    background: #fff;
    margin-bottom: 70px;
}

.requests_add_search {
    padding-bottom: 20px;
    display: flex;
    flex-flow: column;
    position: relative;
    span {
        color: #7f8fa4;
        font-size: 12px;
        padding-bottom: 4px;
    }
    input {
        color: #334150;
        box-sizing: border-box;
        border: 1px solid #c2d1d9;
        border-radius: 3px;
        padding: 0 12px;
        font-size: 14px;
        height: 41px;
        width: 571px;
        font-family: "Akrobat Regular", sans-serif;
        &::placeholder {
            color: #7f8fa4;
        }
    }
}

.amount_requests {
    margin-bottom: 20px;
    display: flex;
    > div:first-of-type {
        display: flex;
        flex-flow: column;
        margin-right: 50px;
    }
    .descriptions_amount {
        padding-bottom: 4px;
        color: #7f8fa4;
        font-size: 12px;
    }
    .amount {
        border: 1px solid #c2d1d9;
        border-radius: 3px;
        height: 41px;
        width: 93px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 20px;
        span {
            width: 23px;
            text-align: center;
        }
        button {
            cursor: pointer;
            background: transparent;
            height: 100%;
            display: flex;
            width: 35px;
            position: relative;
            text-align: center;
            justify-content: center;
            svg {
                font-size: 20px;
                fill: #334150;
            }
        }
    }
    .price_all {
        color: #334150;
        font-size: 23px;
        display: flex;
        flex-flow: column;
        .price {
            color: #334150;
            font-size: 23px;
            line-height: 41px;
        }
    }
    .info_amount {
        color: #7f8fa4;
        font-size: 12px;
        img {
            margin-right: 5px;
        }
    }
    .block_amount {
        display: flex;
        align-items: center;
        > div > button {
            display: flex;
            align-items: center;
        }
    }
}

.block_textarea_amount {
    span {
        padding-bottom: 4px;
        color: #7f8fa4;
        font-size: 12px;
        display: block;
    }
    textarea {
        display: flex;
        resize: none;
        padding: 12px;
        box-sizing: border-box;
        min-height: 59px;
        width: 571px;
        color: #334150;
        border: 1px solid #c2d1d9;
        border-radius: 3px;
        &::placeholder {
            color: #7f8fa4;
        }
    }
}

.attach_file_block {
    padding-top: 20px;
    .attach_btn {
        padding-bottom: 9px;
        color: #0253b3;
        font-size: 12px;
        cursor: pointer;
        background: transparent;
        img {
            margin-right: 5px;
        }
    }
    .document_info {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        .doc_icon {
            margin-right: 5px;
            height: 15px;
            width: 11px;
        }
        span {
            color: #7f8fa4;
            font-size: 12px;
        }
        .btn_delete_doc {
            margin-left: 20px;
            background: transparent;
            cursor: pointer;
        }
    }
}
.search_product_wrapper {
    display: block;
    input {
        border: 1px solid transparent;
        border-radius: 3px;
        height: 41px;
        width: 100%;
        line-height: 41px;
        padding: 0 12px;
        color: #01397c;
        font-family: "Akrobat Regular", sans-serif;
        font-size: 14px;
        font-weight: 300;
        &::placeholder {
            color: #7f8fa4;
        }
    }
}

body {
    #products_popper {
        z-index: 1500;
        .popper_wrapper {
            border: 1px solid #c2d1d9;
            max-height: 400px;
            overflow: auto;
            width: 740px;
        }
    }
}

.search_product_item {
    padding: 15px 0;
    border-bottom: 1px solid #c2d1d9;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
}

.requests_add {
    .search_products_list {
        width: 571px;
        border: 1px solid #c2d1d9;
        border-radius: 3px;
        padding: 0 15px;
        position: absolute;
        left: 0;
        top: 66px;
        background: #fff;
        z-index: 11;
        & > :first-child {
            padding-top: 10px;
        }
        & > :last-child {
            border-bottom: none;
            padding-bottom: 10px;
        }
    }
    .search_product_item {
        padding: 15px 0;
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        cursor: pointer;
        &:hover {
            background: #fff;
        }
    }
    .search_product_item_head {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
            color: #01397c;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            padding: 0;
        }
    }

    .search_product_item_info {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .block_value_currency {
            display: flex;
            font-weight: 600;
            font-family: "Akrobat Regular", sans-serif;
            justify-content: flex-end;
            .ru_value {
                font-weight: 400;
                padding-left: 3px;
            }
        }
        & > div {
            width: 20%;
            .block_label {
                color: #7f8fa4;
                font-size: 12px;
                font-weight: 300;
                line-height: 15px;
            }
            .block_value {
                font-weight: 400;
                color: #334150;
                font-size: 14px;
                line-height: 17px;
            }
        }
        & > :first-of-type {
            width: 25%;
        }
        & > :nth-child(2) {
            width: 20%;
        }
        & > :nth-child(4) {
            width: 15%;
        }
        & > :last-child {
            width: 15%;
            text-align: right;
            line-height: 27px;
            font-size: 23px;
        }
    }
}

.search_product_item_head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h4 {
        color: #01397c;
        font-family: "Akrobat Regular", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    span {
        color: #7f8fa4;
        font-size: 12px;
        line-height: 15px;
    }
    .highlight {
        background: #a4e7ff;
    }
}

.search_product_item_info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    & > div {
        width: 20%;
    }
    & > :last-child {
        text-align: right;
        font-family: Roboto, sans-serif;
        line-height: 27px;
        font-size: 23px;
    }
    .block_value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.select_error_tooltip {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #ff0000;
    }
  }